import React, { FunctionComponent, useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@/components';
import { theme } from '@/components/App';
import { useOnClickOutside } from '@/hooks';

const tooltipWidth = 240;

export type PositionType = 'top' | 'right' | 'bottom' | 'left' | 'childBottom';
type ColorType = 'light' | 'dark';

type TooltipProps = {
  children?: JSX.Element;
  content: string | JSX.Element;
  position?: PositionType;
  marginRight?: string;
  marginLeft?: string;
  color?: 'light' | 'dark';
  showPointer?: boolean;
};

export const Tooltip: FunctionComponent<TooltipProps> = ({
  children = null,
  content,
  color = 'light',
  position = 'right',
  marginRight,
  marginLeft,
  showPointer = true
}) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const closeTooltip = useCallback(() => {
    if (show) setShow(false);
  }, [show]);

  const openTooltip = useCallback(() => {
    if (!show) setShow(true);
  }, [show]);

  const toggle = () => setShow(!show);

  useOnClickOutside(ref, closeTooltip);

  return (
    <Container ref={ref} marginRight={marginRight} marginLeft={marginLeft}>
      <Button
        onClick={toggle}
        onMouseEnter={openTooltip}
        onMouseLeave={closeTooltip}
        showPointer={showPointer}
      >
        {children ? (
          children
        ) : (
          <Icon name="tooltip" width="17px" height="17px" color={theme.colors.grey[800]} />
        )}
      </Button>
      {show && (
        <PopUp position={position} color={color}>
          {content}
        </PopUp>
      )}
    </Container>
  );
};

const Container = styled.div<{ marginRight?: string; marginLeft?: string }>`
  position: relative;
  display: flex;
  margin-right: ${({ marginRight }) => marginRight};
  margin-left: ${({ marginLeft }) => marginLeft};
`;

const Button = styled.button<{ showPointer: boolean }>`
  line-height: 0;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : 'default')};
`;

const PopUp = styled.div<{ position: PositionType; color: ColorType }>`
  padding: 0.7rem;
  z-index: 999;
  ${({ color, theme }) =>
    color === 'light'
      ? css`
          color: ${theme.colors.black};
          background: ${theme.colors.grey[200]};
        `
      : css`
          background-color: ${theme.colors.grey[800]};
          color: ${theme.colors.white};
        `}
  border-radius: 4px;
  font-size: 0.8rem;
  position: absolute;
  word-wrap: break-word;
  white-space: normal;
  width: ${tooltipWidth}px;
  ${({ position }) => {
    if (position === 'top')
      return css`
        top: -50px;
        left: 0;
        transform: translate(-50%, 0);
      `;
    if (position === 'right')
      return css`
        top: 50%;
        left: 25px;
        transform: translate(${tooltipWidth}, -50%);
      `;
    if (position === 'bottom')
      return css`
        top: 25px;
        left: 0;
        transform: translate(-50%, 0);
      `;
    if (position === 'left')
      return css`
        top: 50%;
        left: -${tooltipWidth + 12}px;
        transform: translate(0, -50%);
      `;
    if (position === 'childBottom')
      return css`
        top: 16px;
        left: 0;
      `;
  }}
`;
