import React, { FunctionComponent, useRef, useState, useCallback, useEffect, ChangeEvent } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Column, Spinner } from '@/components';
import { theme } from '@/components/App';
import { toast } from 'react-toastify';
import { DialogFooter } from '@/components/Dialog';
import { TypeImageInput } from '../ImageManagerV2';

type ImageCropperProps = {
  image: File | undefined;
  prevImage: string | undefined;
  setImage: (image: File | undefined) => void;
  onClose: () => void;
  aspectRatio?: number;
  validExts?: string[];
  type?: TypeImageInput;
};

export const ImageCropper: FunctionComponent<ImageCropperProps> = ({
  image,
  prevImage,
  setImage,
  onClose,
  aspectRatio = 1 / 1,
  validExts = ['png', 'jpg', 'jpeg', 'JPG', 'JPEG', 'PNG'],
  type
}) => {
  const cropperRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [imageCropped, setImageCropped] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      setImageCropped(undefined); // This worked for me
      setLoading(true);
    };
  }, []);

  const onCrop = useCallback(() => {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const imageElement: any = cropperRef?.current;
    const cropper = imageElement?.cropper;
    cropper.getCroppedCanvas().toBlob((blob: File) => {
      const myBlob = blob ? Object.assign(blob) : null;
      if (myBlob) {
        myBlob.lastModified = new Date();
        myBlob.name = image?.name;
        setImageCropped(myBlob);
      } else {
        setImageCropped(undefined);
      }
    });
  }, [setImageCropped, image]);

  const onSave = useCallback(() => {
    setImage(imageCropped);
    onClose();
  }, [imageCropped, setImage, onClose]);

  useEffect(() => {
    const handleEnter = (event: any) => {
      if (event.keyCode === 13) onSave();
    };
    window.addEventListener('keydown', handleEnter);

    return () => window.removeEventListener('keydown', handleEnter);
  }, [onSave]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    const type = file.name.split('.').pop();
    const isValidFile = type && validExts.includes(type);
    if (isValidFile) setImage(file);
    else toast.error('La imagen debe ser un archivo en formato .jpg, .jpeg o .png');

    e.target.value = '';
  };

  const handleClick = () => {
    inputRef?.current?.click();
  };

  return (
    <>
      {type === TypeImageInput.AVATAR && (
        <style>
          {`
    .cropper-view-box {
      border-radius: 50%
    }
    .`}
        </style>
      )}
      <Column align="center" gap={16} justify="space-between" className="w-full w-hull">
        {loading && (
          <div className="flex w-full h-[40vh] m-auto items-center justify-center">
            <Spinner size={23} background={theme.colors.grey[400]} />
          </div>
        )}
        <Cropper
          src={prevImage}
          style={{
            height: '60vh',
            width: '100%',
            margin: 'auto',
            ...(loading && { visibility: 'hidden' })
          }}
          // Cropper.js options
          aspectRatio={aspectRatio}
          autoCropArea={aspectRatio}
          guides={true}
          crop={onCrop}
          ref={cropperRef}
          ready={() => {
            setLoading(false);
          }}
          checkCrossOrigin={false}
        />
        <DialogFooter className="w-full">
          <Button fullWidth size="md" variant="outlined" onClick={handleClick}>
            Usar otra
            <input className="hidden" ref={inputRef} type="file" onChange={onChange} />
          </Button>
          <Button fullWidth size="md" onClick={onSave}>
            Guardar
          </Button>
        </DialogFooter>
      </Column>
    </>
  );
};
