import { ResponsiveContainer, Row, SkeletonTable } from '@/components';
import { theme } from '@/components/App';
import { useClient, useWindowResize } from '@/hooks';
import { VendorClientsBaseI } from '@/types/cyclone/models';
import { ErrorI, GetClientBaseI } from '@/types/cyclone/requests';
import { faFileArrowDown, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { EmptyObject } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { EmptyMessage, ErrorContainer, ErrorImage } from '../Activities/styles';
import { emptyActivities } from '@/static/images';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import { ContactModal } from '../EventDetails/components';
import { useAuth0 } from '@auth0/auth0-react';
import { saveAs } from 'file-saver';
import { trackGenericEvent } from '@/analytics';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Input } from '@/components/Input';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import TableV2 from '@/components/Table/TableV2';
import { GetUserName, handleCopy } from '../Activities/commons';

type ClientsTable = {
  client: VendorClientsBaseI;
  username: React.ReactNode;
  phone: string;
  timestamp: string;
  contact: React.ReactNode;
};

const API_URL = import.meta.env.VITE_CYCLONE_API_URL || 'INSERT_CYCLONE_API_URL';

export const Clients = () => {
  useMixpanelTrackPage('Clients');
  const { client } = useClient();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [search, setSearch] = useState<string>('');
  const [selectedClient, setSelectedClient] = useState<VendorClientsBaseI | undefined>(undefined);
  const [showContactModal, setShowContactModal] = useState(false);
  const { isMobileSize } = useWindowResize();
  const [height, setHeight] = useState<number>(0);

  const { data: _clients, isLoading } = useQuery(
    ['client_base'],
    async () =>
      await client<GetClientBaseI>(`me/vendor/client_base`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const clients = _clients?.filter((client) => {
    const { user } = client;
    if (user) {
      // Lowercase and trim first name, last name, and email for comparison
      const firstName = user.first_name ? user.first_name.toLowerCase().trim() : '';
      const lastName = user.last_name ? user.last_name.toLowerCase().trim() : '';
      const email = user.email ? user.email.toLowerCase().trim() : '';
      const searchTerm = search.toLowerCase().trim();

      // Check if firstName, lastName, or email includes the searchTerm.
      return firstName.includes(searchTerm) || lastName.includes(searchTerm) || email.includes(searchTerm);
    }
    return false;
  });

  useEffect(() => {
    const root = document.getElementById('root');
    const main = document.getElementById('main');
    if (root && main) {
      root.style.maxHeight = '100vh';
      root.style.overflow = 'hidden';
      main.style.overflow = 'auto';

      setHeight(main.clientHeight - 180);
    }
    return () => {
      const root = document.getElementById('root');
      const main = document.getElementById('main');
      if (root && main) {
        main.scrollTop = 0;
        root.style.maxHeight = 'auto';
        root.style.overflow = 'auto';
        main.style.overflow = 'initial';
      }
    };
  }, []);

  const mutation = useMutation<EmptyObject, ErrorI, { message: string }>(
    (message) =>
      client(`me/vendor/contact/${selectedClient?.user?.id}`, 'POST', {
        isAuthRequired: true,
        data: message
      }),
    {
      onSuccess: () => {
        toast.success(`Mensaje enviado a ${selectedClient?.user?.first_name}`);
      },
      onError: () => {
        toast.error('Algo anda mal. Por favor, contactar a soporte.');
      }
    }
  );

  const handleContact = (message: string) => mutation.mutate({ message });

  const handleOpenContactModal = (client: VendorClientsBaseI) => {
    setSelectedClient(client);
    setShowContactModal(true);
  };

  const getColumnsClients = (): ColumnDef<ClientsTable, any>[] => {
    const columnHelper = createColumnHelper<ClientsTable>();

    return [
      columnHelper.accessor((row) => row.username, {
        id: 'timestamp',
        header: () => <span>Cliente</span>,
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor((row) => row.phone, {
        id: 'username',
        header: () => <span>Telefono</span>,
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor((row) => row.timestamp, {
        id: 'schedule',
        header: () => <span>Registro</span>,
        cell: (info) => info.getValue()
      }),
      columnHelper.accessor((row) => row.contact, {
        id: 'service',
        header: () => <span>Contactar</span>,
        cell: (info) => info.getValue()
      })
    ];
  };

  const body: ClientsTable[] =
    clients?.map((client) => {
      const { user } = client;

      // Add a + to the phone number
      const phone = user?.phone && `+${user.phone}`;

      return {
        client,
        username: <GetUserName clients={clients} onClick={(text) => handleCopy(text)} user={user} />,
        phone: phone ?? '-',
        timestamp: dayjs(user?.created_at).format('DD/MM/YYYY'),
        contact: (
          <Row align="center" gap={12}>
            <button onClick={() => handleOpenContactModal(client)}>
              <FontAwesomeIcon icon={faEnvelope} size="lg" style={{ color: theme.colors.grey[700] }} />
            </button>
            {phone && (
              <button
                onClick={() => window.open(`https://api.whatsapp.com/send?phone=${user.phone}`, '_blank')}
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" style={{ color: theme.colors.grey[700] }} />
              </button>
            )}
          </Row>
        )
      };
    }) || [];

  const selectInput = () => (
    <Row align="center" gap={10} className="w-full">
      <div className="w-full md:w-[260px]">
        <Input
          rightIcon="search"
          inputId="search-bar"
          placeholder={isMobileSize ? 'Buscar' : 'Buscar por usuarios'}
          capitalize={false}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />
      </div>
    </Row>
  );

  const renderReports = () => {
    const downloadClients = async () => {
      trackGenericEvent('Button Download Report Clicked', {
        type: 'clients'
      });

      const token = await getAccessTokenSilently();
      const dateNow = dayjs().format('YYYY-MM-DDTHH:mmD');
      const res = await fetch(`${API_URL}/me/vendor/reports/client_base`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const document = await res.blob();

      saveAs(document, `clients_${dateNow}.xlsx`);
    };
    return (
      <Row
        align="center"
        gap={8}
        className="w-fit sm:w-full cursor-pointer bg-[#E9E9E9] rounded p-2 hover:bg-[#dadada] transition-colors duration-300 px-4 py-0 md:py-2"
        onClick={downloadClients}
      >
        <FontAwesomeIcon icon={faFileArrowDown} color="#626262" size="lg" />
        {!isMobileSize && <p className="text-[#626262]">Descargar reporte</p>}
      </Row>
    );
  };

  return (
    <ResponsiveContainer className="my-4">
      <div className="flex flex-col w-full gap-4 sm:gap-0 sm:flex-row sm:items-center sm:justify-between mb-6">
        <Row align="center" className="w-1/2 sm:w-auto">
          <div className="flex w-full items-center relative h-[46px] border-r pr-4 mr-4">
            <h1 className="text-lg sm:text-2xl leading-none">Clientes</h1>
          </div>
          <div className="h-[46px] px-4 flex gap-2 items-center bg-white border rounded p-2 text-[#626262] transition-colors duration-300">
            <FontAwesomeIcon icon={faUsers} />
            <div className="badge pb-0.5">{_clients?.length}</div>
          </div>
        </Row>
        <Row gap={16}>
          {selectInput()}
          {renderReports()}
        </Row>
      </div>
      <div className="sm:pt-7">
        {isLoading ? (
          // Display skeleton loader when data is loading
          <SkeletonTable rows={5} />
        ) : clients && clients.length > 0 ? (
          // Display the actual content when data is loaded and there are clients
          <TableV2
            columns={getColumnsClients()}
            data={body}
            maxHeight={height}
            onRowClick={(row) => navigate(`/clientes/${row.client.id}`)}
          />
        ) : (
          // Display an error or empty message if there's no data
          <ErrorContainer>
            <ErrorImage src={emptyActivities} />
            <EmptyMessage>Todavía no tenés actividad para mostrar</EmptyMessage>
          </ErrorContainer>
        )}
      </div>
      {showContactModal && (
        <ContactModal
          setShowModal={setShowContactModal}
          onSubmit={handleContact}
          contactTo={selectedClient?.user?.first_name || ''}
        />
      )}
    </ResponsiveContainer>
  );
};
