import { Icon, Row } from '@/components';
import { theme } from '@/components/App';
import { LinkI } from '@/containers/StoreFrontBuilder/context/storefront';
import { AgoraLinkTypeE } from '@/types/cyclone/models';
import { faArrowsUpDown, faEdit, faEye, faEyeSlash, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

interface LinkItemPropsI {
  link: LinkI;
  position: number;
  removeLink: (position: number) => void;
  editLink: (position: number) => void;
  handleModalLink: (type: AgoraLinkTypeE, position: number) => void;
}

export const LinkItem: FunctionComponent<LinkItemPropsI> = ({
  link,
  position,
  removeLink,
  editLink,
  handleModalLink
}) => {
  const getIcon = (type: AgoraLinkTypeE) => {
    switch (type) {
      case AgoraLinkTypeE.CUSTOM:
        return <Icon name="custom_link" width="17" height="17" color={theme.colors.grey[700] as string} />;
      case AgoraLinkTypeE.YOUTUBE:
        return <Icon name="youtube_not_bg" color="#FF0000" width="17" height="17" />;
      default:
        return <Icon name="agora_logo_outline" width="17" height="17" color={theme.colors.black as string} />;
    }
  };

  return (
    <Row align="center" justify="space-between" className="w-full px-3 py-2 rounded bg-[#fafafa] mb-5">
      <Row align="center" gap={7} className="truncate">
        {getIcon(link.type)}
        <p className="text-base font-medium truncate">{link.title}</p>
      </Row>
      <Row align="center" justify="flex-end" gap={20}>
        <Row
          as="div"
          align="center"
          justify="center"
          onClick={() => editLink(position)}
          className="rounded-full bg-white h-8 w-8 cursor-pointer"
        >
          <FontAwesomeIcon icon={faEdit} color="#0072fb" />
        </Row>
        <Row
          as="div"
          align="center"
          justify="center"
          onClick={
            link.type !== AgoraLinkTypeE.CUSTOM && link.type !== AgoraLinkTypeE.YOUTUBE
              ? () => handleModalLink(link.type, position)
              : () => removeLink(position)
          }
          className="rounded-full bg-white h-8 w-8 cursor-pointer"
        >
          {link.type !== AgoraLinkTypeE.CUSTOM && link.type !== AgoraLinkTypeE.YOUTUBE ? (
            <FontAwesomeIcon
              icon={link.isHidden ? faEyeSlash : faEye}
              color={link.isHidden ? '#DCDCDC' : '#0072fb'}
            />
          ) : (
            <FontAwesomeIcon icon={faTrash} color="#0072fb" />
          )}
        </Row>
        <Row
          as="div"
          align="center"
          justify="center"
          className="rounded-full bg-white h-8 w-8 cursor-pointer"
        >
          <FontAwesomeIcon icon={faArrowsUpDown} color="#0072fb" />
        </Row>
      </Row>
    </Row>
  );
};
