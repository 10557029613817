import { Grid, ResponsiveContainer, Spinner } from '@/components';
import { AgoraLogo } from '@/components/Navbar/components';
import React from 'react';
import { useOnboarding } from '../../contexts';
import { ProgressBar } from '../ProgressBar';
import classNames from 'classnames';
import { useWindowResize } from '@/hooks';

export const NextStepButton: React.FC<{ fullWidth?: boolean }> = ({ fullWidth = false }) => {
  const {
    step,
    onSubmitChildCategory,
    onSubmitLocation,
    onSubmitPhone,
    fullSubmitSiteData,
    onSubmitLinkAccounts,
    loadingState
  } = useOnboarding();

  const handleButtonOnClick = () => {
    switch (step) {
      case 2:
        onSubmitChildCategory();
        break;
      case 3:
        onSubmitLocation();
        break;
      case 4:
        onSubmitPhone();
        break;
      case 5:
        fullSubmitSiteData();
        break;
      case 6:
        onSubmitLinkAccounts();
        break;
      default:
        break;
    }
  };

  const handleButtonText = () => {
    const stepTextMap: { [key: number]: string } = {
      1: '',
      2: 'Siguiente',
      3: 'Guardar',
      4: 'Guardar',
      5: 'Guardar',
      6: 'Siguiente',
      7: ''
    };

    return stepTextMap[step] || '';
  };

  return (
    <div className="flex justify-end items-center w-auto h-[48px] ">
      <button
        onClick={handleButtonOnClick}
        className={classNames(
          'bg-blue text-white rounded flex justify-center items-center font-medium text-lg h-[40px]',
          {
            invisible: step === 1 || step === 7,
            'w-full': fullWidth,
            'w-[120px]': !fullWidth
          }
        )}
        disabled={loadingState}
      >
        {loadingState ? <Spinner color="#fff" size={20} /> : handleButtonText()}
      </button>
    </div>
  );
};

const BackButton: React.FC = () => {
  const { step, setStep, fatherCategory, childCategories, loadingState } = useOnboarding();

  const handleButtonOnClick = () => {
    if (step === 3 && !fatherCategory) setStep(1);
    else if (step === 3 && fatherCategory?.name !== 'Belleza y Estética') setStep(1);
    else if (step === 3 && !childCategories) setStep(1);
    else setStep(step - 1);
  };

  return (
    <div className="flex justify-start items-center w-auto h-[48px]">
      <button
        onClick={handleButtonOnClick}
        disabled={loadingState}
        className={classNames('text-blue font-medium px-4 py-2 text-lg', {
          invisible: step === 1
        })}
      >
        Volver
      </button>
    </div>
  );
};

export const Navbar: React.FC = () => {
  const { step } = useOnboarding();
  const { isMobileSize } = useWindowResize();

  const progress = (step / 7) * 100;
  return (
    <header className="sticky top-0 left-0 h-[48px] right-0 bottom-0 bg-white z-50">
      <ResponsiveContainer>
        <Grid as="div" columns={isMobileSize ? 2 : 3} className="w-full h-[48px]">
          <BackButton />
          {!isMobileSize && (
            <div className="flex justify-center items-center w-auto h-[48px]">
              <AgoraLogo href={''} />
            </div>
          )}
          {isMobileSize && step === 1 && (
            <div className="flex justify-center items-center w-auto h-[48px]">
              <AgoraLogo href={''} />
            </div>
          )}

          <NextStepButton />
        </Grid>
      </ResponsiveContainer>
      <ProgressBar value={progress} />
    </header>
  );
};
