import { Column, Row } from '@/components';
import { useOnboarding } from '@/containers/SelfOnboarding/contexts';
import { useWindowResize } from '@/hooks';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { SelectV2, SelectProps } from '@/components/SelectV2';
import { Transition } from '@headlessui/react';
import { SupportedCountriesE } from '@/types/cyclone/models';
import { InputAddress } from '@/components/Input/InputAddress';
import { NextStepButton } from '../components/Navbar/Navbar';
import { Map } from '@/components/Map';

export const Location: React.FC = () => {
  const {
    selectedPlace,
    handleSelectPlace,
    notPlaceSelected,
    handleNotPlaceSelected,
    country,
    setCountry,
    setTimeZone
  } = useOnboarding();
  const { isMobileSize } = useWindowResize();
  const [selectedCountry, setSelectedCountry] = React.useState<SelectProps | null>(null);
  const [selectedTimezone, setSelectedTimezone] = React.useState<SelectProps | null>(null);

  const initialMapCenter = React.useMemo(() => {
    switch (selectedCountry?.value) {
      case SupportedCountriesE.ARGENTINA:
        return 'Argentina';
      case SupportedCountriesE.URUGUAY:
        return 'Uruguay';
      case SupportedCountriesE.CHILE:
        return 'Chile';
      default:
        return 'Argentina';
    }
  }, [selectedCountry]);

  const countryOptions: SelectProps[] = [
    {
      value: SupportedCountriesE.ARGENTINA,
      label: '🇦🇷 Argentina'
    },
    {
      value: SupportedCountriesE.CHILE,
      label: '🇨🇱 Chile'
    },
    {
      value: SupportedCountriesE.URUGUAY,
      label: '🇺🇾 Uruguay'
    }
  ];

  const timezoneOptions: Record<SupportedCountriesE, SelectProps[]> = {
    [SupportedCountriesE.ARGENTINA]: [
      {
        value: 'America/Argentina/Buenos_Aires',
        label: 'Argentina - Buenos Aires (UTC-3)'
      }
    ],
    [SupportedCountriesE.URUGUAY]: [
      {
        value: 'America/Montevideo',
        label: 'Uruguay - Montevideo (UTC-3)'
      }
    ],
    [SupportedCountriesE.CHILE]: [
      {
        value: 'America/Santiago',
        label: 'Chile - Santiago (UTC-4)'
      },
      {
        value: 'America/Punta_Arenas',
        label: 'Chile - Punta Arenas (UTC-3)'
      }
    ]
  };

  useEffect(() => {
    if (country) {
      setSelectedCountry(
        countryOptions.find((option) => option.value === country) || {
          value: country,
          label: '🌎 Otro'
        }
      );

      if (Object.values(SupportedCountriesE).includes(country as SupportedCountriesE)) {
        const countryTimezones = timezoneOptions[country as SupportedCountriesE];

        if (countryTimezones.length === 1) {
          setSelectedTimezone(countryTimezones[0]);
          setTimeZone(countryTimezones[0].value as string);
        } else {
          setSelectedTimezone(null);
        }
      } else {
        setSelectedTimezone(null);
      }
    }
  }, [country]);

  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryValue = selectedCountry.value as SupportedCountriesE;
      const countryTimezones = timezoneOptions[selectedCountryValue];

      if (countryTimezones.length === 1) {
        setSelectedTimezone(countryTimezones[0]);
      } else {
        setSelectedTimezone(null);
      }
    }
  }, [selectedCountry]);

  const handleSelectCountry = (country: SelectProps | null) => {
    setSelectedCountry(country);

    if (country) {
      setCountry(country.value as SupportedCountriesE);
    }
  };

  return (
    <Column
      align="center"
      className={classNames({
        'w-full p-8 min-h-[calc(100vh-48px)]': !isMobileSize,
        'w-full py-3': isMobileSize
      })}
    >
      <h2 className="font-medium text-3xl mt-4 text-center text-balance">Ubicación de tu negocio</h2>
      <p className="text-base text-[#626262] font-medium mt-2 text-center text-balance">
        Asignaremos esta dirección como predeterminada a tus servicios presenciales.
      </p>
      <Column gap={16} className="md:w-1/2 w-full py-4 px-6 mt-4">
        <p className="font-medium text-lg">Selecciona el país donde te encuentras</p>
        <SelectV2 selected={selectedCountry} setSelected={handleSelectCountry} options={countryOptions} />
        <Transition
          show={selectedCountry !== null}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <>
            <div className="relative w-full">
              <p className="font-medium text-lg">Selecciona tu zona horaria</p>
              <SelectV2
                selected={selectedTimezone}
                setSelected={(timezone) => {
                  setSelectedTimezone(timezone);
                  // @ts-ignore
                  setTimeZone(timezone?.value ?? '');
                }}
                options={selectedCountry ? timezoneOptions[selectedCountry.value as SupportedCountriesE] : []}
                disabled={
                  selectedCountry
                    ? timezoneOptions[selectedCountry.value as SupportedCountriesE].length === 1
                    : true
                }
              />

              <p className="font-medium text-lg mt-4 mb-2">Ingresa la dirección de tu negocio</p>
              <InputAddress
                selectedPlace={selectedPlace}
                onSelect={(place) => handleSelectPlace(place)}
                country={country}
              />
            </div>
            <div className={classNames('py-4', { hidden: !selectedPlace })}>
              <Map height="300px" address={selectedPlace || initialMapCenter} zoom={selectedPlace ? 15 : 5} />
            </div>
            <Row className="form-control mt-2 mb-4">
              <label className="label gap-2 p-0 cursor-pointer">
                <input
                  type="checkbox"
                  checked={notPlaceSelected}
                  onChange={handleNotPlaceSelected}
                  className="checkbox w-5 h-5 rounded checkbox-primary"
                />
                <span className="text-base font-book">
                  Sólo brindo servicios online / no tengo una dirección específica.
                </span>
              </label>
            </Row>
          </>
          <NextStepButton fullWidth />
        </Transition>
      </Column>
    </Column>
  );
};
