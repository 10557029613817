import React, { FunctionComponent } from 'react';
import { Button, Row, Column } from '@/components';
import { mercado_pago, stepFourMockup, stripe } from '@/static/images';
import { useWindowResize } from '@/hooks';
import { SupportedCountriesE, WalletTypeE } from '@/types/cyclone/models';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { trackGenericEvent } from '@/analytics';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { DialogMercadoPago } from '../components';
import { useOnboarding } from '../contexts';

export const LinkAccounts: FunctionComponent = () => {
  useMixpanelTrackPage('Onboarding', { step: 'PaymentMethods' });
  const { isMobileSize } = useWindowResize();
  const {
    handleMPConnect,
    interestedInternationalPayment,
    setInterestedInternationalPayment,
    offlinePaymentEnabled,
    setOfflinePaymentEnabled,
    showConfirmationMP,
    setShowConfirmationMP,
    paymentGateways,
    togglePrivacyPolicies,
    privacyPolicies,
    loadingConnectionToMP,
    country
  } = useOnboarding();

  const isSupportedMP = country === SupportedCountriesE.ARGENTINA || country === SupportedCountriesE.URUGUAY;

  return (
    <>
      <Row className="w-full max-h-[calc(100vh-48px)]">
        <Column
          className={classNames('gap-3', {
            'w-3/5 p-8 h-[calc(100vh-48px)]': !isMobileSize,
            'w-full py-3': isMobileSize
          })}
        >
          <Column
            className={classNames('gap-3', {
              'max-h-[calc(100vh-68px)] overflow-y-auto border-r-[#868686] scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full':
                !isMobileSize,
              'my-4': isMobileSize
            })}
          >
            <h2 className="font-medium text-3xl text-center text-balance">Medios de cobro</h2>
            {isSupportedMP && (
              <>
                <Column gap={20} className="mt-4">
                  <Row align="center" className={isMobileSize ? 'justify-between' : 'gap-5'}>
                    <h3 className="font-medium sm:text-lg text-base">• Pagos online desde {country}</h3>
                    <img
                      width={isMobileSize ? 85 : 120}
                      height="auto"
                      className="object-contain"
                      src={mercado_pago}
                    />
                  </Row>
                  <p className="text-[#626262] sm:text-base text-sm w-full sm:w-2/3">
                    Procesamos los pagos de tus clientes en {country} mediante Mercado Pago.
                  </p>
                  {!paymentGateways.includes(WalletTypeE.MERCADO_PAGO) ? (
                    <div className="w-full sm:w-48">
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          trackGenericEvent('Button Selfonboard Connect App Clicked', {
                            name_app: 'Mercado Pago'
                          }),
                            setShowConfirmationMP(true);
                        }}
                        loading={loadingConnectionToMP}
                      >
                        Conectar
                      </Button>
                    </div>
                  ) : (
                    <Row align="center" gap={10}>
                      <FontAwesomeIcon icon={faCheck} fontSizeAdjust={14} color="#25D366" />
                      <p className="text-sm text-[#25D366]">Conectado</p>
                    </Row>
                  )}
                </Column>
                <div className="my-5 sm:my-2 h-[1px] w-full block border-t border-[#DADCE0] sm:border-none" />
              </>
            )}
            <Column gap={20}>
              <h3 className="font-medium sm:text-lg text-base">• Efectivo y transferencias</h3>
              <p className="text-[#626262] sm:text-base text-sm w-full sm:w-2/3">
                Pagos en efectivo o mediante transferencia bancaria. Podrás indicar instrucciones de pago para
                tus clientes.
              </p>
              <Row align="center" gap={5} className="form-control">
                <label className="label gap-2 p-0 cursor-pointer">
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={offlinePaymentEnabled}
                    onClick={() => setOfflinePaymentEnabled(!offlinePaymentEnabled)}
                  />
                  <p className="sm:text-base text-sm">Habilitar pagos en efectivo / transferencia</p>
                </label>
              </Row>
            </Column>
            <div className="my-5 sm:my-2 h-[1px] w-full block border-t border-[#DADCE0] sm:border-none" />
            <Column gap={20}>
              <Row align="center" className={isMobileSize ? 'justify-between' : 'gap-5'}>
                <h3 className="font-medium sm:text-lg text-base">• Pagos online internacionales</h3>
                <img width={isMobileSize ? 60 : 70} height="auto" className="object-contain" src={stripe} />
              </Row>
              <p className="text-[#626262] sm:text-base text-sm w-full sm:w-2/3 [&>a]:font-medium [&>a]:text-[#0072FB] [&>a]:cursor-pointer">
                Pagos de tus clientes en otros países mediante{' '}
                <a href="https://stripe.com/es" target="_blank" rel="noreferrer">
                  Stripe
                </a>
                . Podrás indicarnos cómo recibir los fondos.
              </p>
              <Row align="center" gap={5} className="form-control">
                <label className="label gap-2 p-0 cursor-pointer">
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={interestedInternationalPayment}
                    onClick={() => setInterestedInternationalPayment(!interestedInternationalPayment)}
                  />
                  <p className="sm:text-base text-sm">Habilitar cobros internacionales</p>
                </label>
              </Row>
            </Column>
            <div className="my-5 h-[1px] w-full block border-t border-[#DADCE0]" />
            <Row className="form-control">
              <label className="label gap-2 p-0 cursor-pointer">
                <input
                  type="checkbox"
                  checked={privacyPolicies}
                  onChange={togglePrivacyPolicies}
                  className="checkbox w-5 h-5 rounded checkbox-primary"
                />
                <span className="text-sm sm:text-base [&>a]:text-[#0072FB] [&>a]:cursor-pointer">
                  Acepto{' '}
                  <a
                    href="https://agora.red/terminos-y-condiciones-proveedores"
                    target="_blank"
                    rel="noreferrer"
                  >
                    términos
                  </a>{' '}
                  y{' '}
                  <a href="https://agora.red/politicas-de-privacidad" target="_blank" rel="noreferrer">
                    política de privacidad
                  </a>{' '}
                  de Ágora
                </span>
              </label>
            </Row>
          </Column>
        </Column>
        {!isMobileSize && (
          <Column gap={15} className="w-2/5 h-[calc(100vh-48px)] border-l p-8">
            <p className="text-base text-[#626262]">Habilitá distintos medios de pago para tus clientes.</p>
            <p className="text-base text-[#626262]">
              Los medios de pago online te permitirán cobrar tus servicios por anticipado.
            </p>
            <div
              style={{
                backgroundImage: `url(${stepFourMockup})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: '100%'
              }}
            />
          </Column>
        )}
      </Row>
      <DialogMercadoPago
        show={showConfirmationMP}
        setShow={setShowConfirmationMP}
        onClick={handleMPConnect}
      />
    </>
  );
};
