import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import { IconName } from '../Icon';

type InputProps = {
  shadow?: boolean;
  placeholder?: string;
  value?: string;
  onChange: (description: string) => void;
  helpText?: string | JSX.Element;
  maxLength?: number;
  showCount?: boolean;
  inputId?: string;
  error?: string;
  leftIcon?: IconName;
  fullWidth?: boolean;
  rows?: number;
  capitalize?: boolean;
  handleCheckboxClick?: (checkboxState: boolean) => void;
  isEdit?: boolean;
  placeholderEditor?: string;
  disabled?: boolean;
};

const initEditorState = (value: string | undefined): EditorState => {
  if (value) {
    const blocksFromHtml = htmlToDraft(value);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  } else {
    return EditorState.createEmpty();
  }
};

export const InputRichText: FunctionComponent<InputProps> = ({
  placeholderEditor = '',
  value,
  onChange,
  helpText,
  showCount,
  maxLength,
  error,
  shadow,
  fullWidth,
  rows = 200,
  isEdit = false,
  disabled = false
}) => {
  const editorRef = useRef(null);

  const [editorValue, setEditorValue] = useState(initEditorState(value));
  const [firstLoad, setFirstLoad] = useState(false);

  const showBottom = helpText || showCount;

  const onEditorStateChange = (editorStateValue: EditorState) => {
    if (maxLength && editorStateValue.getCurrentContent().getPlainText().length > maxLength) return;

    const newValue = draftToHtml(convertToRaw(editorStateValue.getCurrentContent()));

    if (editorStateValue.getCurrentContent().getPlainText().trim() === '') {
      onChange('');
    } else {
      onChange(newValue);
    }

    setEditorValue(editorStateValue);
  };

  useEffect(() => {
    if (isEdit) {
      if (!firstLoad && value !== '' && isEdit) {
        setEditorValue(initEditorState(value));
        setFirstLoad(true);
      }
    }
  }, [value]);

  const toolbarElement = document.querySelector('.rdw-editor-toolbar');
  const toolbarElementStyle = toolbarElement?.getBoundingClientRect();
  const toolbarHeight = toolbarElementStyle?.height;

  return (
    <div className={fullWidth ? 'w-full max-w-[750px]' : 'max-w-[750px]'}>
      <div
        className={`relative flex justify-center w-full items-center p-4 rounded bg-white ${
          error ? 'border border-red-200' : 'border border-gray-900/36'
        } ${shadow ? 'border border-gray-100 shadow-md' : ''} h-[${rows}px]`}
        ref={editorRef}
      >
        <style>
          {`
              .rdw-editor-wrapper {
                height: calc(${rows}px - ${toolbarHeight || 40}px);
                width: 100%;
              }
              .rdw-editor-main {
                height: calc(100% - ${toolbarHeight || 40}px);
                width: 100%;
              }
            `}
        </style>
        <Editor
          readOnly={disabled}
          handlePastedText={() => false}
          placeholder={placeholderEditor}
          editorState={editorValue}
          onEditorStateChange={onEditorStateChange}
          localization={{
            locale: 'es'
          }}
          toolbar={{
            options: ['colorPicker', 'emoji', 'inline', 'list', 'link', 'textAlign'],
            colorPicker: {
              colors: [
                '#000000',
                '#ffffff',
                '#7f7f7f',
                '#cccccc',
                '#ff0000',
                '#6600ff',
                '#9900cc',
                '#99ccff',
                '#cc00cc',
                '#cc99ff',
                '#cc99cc',
                '#ff99cc',
                '#ffcc99',
                '#ccffcc',
                '#ffccff'
              ],
              options: []
            },
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline']
            },
            list: { inDropdown: false, options: ['unordered', 'ordered'] },
            textAlign: { options: ['left', 'center', 'right'], inDropdown: false },
            link: {
              inDropdown: false,
              showOpenOptionOnHover: false,
              defaultTargetOption: '_blank',
              options: ['link']
            }
          }}
        />
      </div>
      {showBottom && (
        <div className="flex justify-between mt-1">
          {(helpText || error) && (
            <span
              className={`flex text-sm ml-4 ${error ? 'text-red-200' : 'text-gray-700'} ${
                showCount ? 'mr-8' : ''
              }`}
            >
              {error || helpText}
            </span>
          )}
          {maxLength && showCount && (
            <span className={`flex text-blue-500 text-sm ${!helpText && 'w-full justify-end'}`}>
              {editorValue.getCurrentContent().getPlainText().length || 0}/{maxLength}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
