import { Button, Column, Row } from '@/components';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { InputDatepicker, InputTimePicker } from '@/components/Input';
import { VendorI } from '@/types/cyclone/models';
import { getDayWeekName } from '@/utils';
import { getGMTOffset } from '@/utils/schedule';
import { faChevronRight, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { FunctionComponent } from 'react';

type Props = {
  vendor: VendorI;
  modalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  onClick: () => void;
  eventHour: { start: string; end: string };
  setEventHour: React.Dispatch<React.SetStateAction<{ start: string; end: string }>>;
  eventDate: Date;
  setEventDate: React.Dispatch<React.SetStateAction<Date>>;
  title?: string;
};

export const ModalRelaunch: FunctionComponent<Props> = ({
  vendor,
  eventHour,
  setEventHour,
  modalOpen,
  setModalOpen,
  onClick,
  eventDate,
  setEventDate,
  title
}) => {
  const getDuration = () => {
    const [startHour, startMinute] = eventHour.start.split(':');
    const [endHour, endMinute] = eventHour.end.split(':');

    const startDate = dayjs(eventDate);
    let endDate = dayjs(eventDate);

    if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) {
      endDate = endDate.add(1, 'day'); // Increment endDate by 1 day if endTime is on the next day or same time
    }

    const startAt = startDate.hour(parseInt(startHour)).minute(parseInt(startMinute)).second(0);
    const endAt = endDate.hour(parseInt(endHour)).minute(parseInt(endMinute)).second(0);

    const durationInMilliseconds = endAt.diff(startAt);
    const durationInMinutes = durationInMilliseconds / (1000 * 60);

    const days = Math.floor(durationInMinutes / (60 * 24));
    const hours = Math.floor((durationInMinutes % (60 * 24)) / 60);
    const minutes = Math.floor(durationInMinutes % 60);

    const formattedDuration = [];
    if (days > 0) {
      formattedDuration.push(`${days} dia${days > 1 ? 's' : ''}`);
    }
    if (hours > 0) {
      formattedDuration.push(`${hours} hora${hours > 1 ? 's' : ''}`);
    }
    if (minutes > 0) {
      formattedDuration.push(`${minutes} minuto${minutes > 1 ? 's' : ''}`);
    }

    return formattedDuration.join(', ');
  };

  return (
    <Dialog
      open={modalOpen}
      onOpenChange={() => {
        setModalOpen(false);
      }}
    >
      <DialogContent>
        <DialogHeader className="pb-4 mb-4 border-b border-[#DACCE0]">
          <div className="grid grid-cols-4 w-full">
            <div />
            <DialogTitle className="col-span-2">{title}</DialogTitle>
            <DialogClose className="place-self-end" />
          </div>
        </DialogHeader>
        <DialogDescription className="pb-4 mb-4">
          Indica la nueva fecha de inicio de tu evento, luego podes volver a editarla
        </DialogDescription>
        <Column justify="space-between" className="min-h-[50vh]">
          <Column className="w-full bg-[#FFF] border border-[#F4F4F4] rounded">
            <Column gap={15} className="py-2 px-4">
              <Row align="center" gap={5} className="w-fit text-lg">
                {eventDate && <p>{`${getDayWeekName(eventDate)},`}</p>}
                <InputDatepicker
                  setDate={(date) => setEventDate(date)}
                  date={eventDate}
                  customInput={false}
                  className="text-lg cursor-pointer w-fit rounded hover:text-[#0072FB] 
                        focus:outline-none focus:shadow-outline transition-colors duration-200 ease-in-out"
                />
              </Row>
              <Row align="center" justify="space-between">
                <InputTimePicker
                  initialValue={eventHour.start}
                  onTimeChange={(time) => setEventHour({ start: time, end: eventHour.end })}
                />
                <FontAwesomeIcon icon={faChevronRight} className="mx-5" color="#868686" />
                <InputTimePicker
                  initialValue={eventHour.end}
                  onTimeChange={(time) => setEventHour({ start: eventHour.start, end: time })}
                />
              </Row>
              <span className="text-base">Duración: {getDuration()}</span>
            </Column>
            <Row
              align="center"
              gap={5}
              className="text-[#868686] text-sm border-t border-[#F4F4F4] py-2 px-4"
            >
              <FontAwesomeIcon icon={faClock} />
              <p>
                {getGMTOffset(vendor.timezone)} {vendor.country}
              </p>
            </Row>
          </Column>
        </Column>
        <DialogFooter>
          <Button fullWidth onClick={onClick}>
            Publicar Evento
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
