import { Column, Row, Toggle } from '@/components';
import { theme } from '@/components/App';
import { useStoreFront } from '@/containers/StoreFrontBuilder/context';
import useScreenSize from '@/hooks/useScreenSize';
import { SizeImageE } from '@/types/cyclone/models';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { InputMedia, SectionContent, SectionSocialMedia } from '../components';
import { InputImage } from '@/components/Input/InputImage';
import { TypeImageInput } from '@/components/ImageManagerV2';

export const SectionHeadboard: FunctionComponent = () => {
  const {
    description,
    handlerDescription,
    name,
    handlerName,
    selectMediaHandler,
    handlerEditMedia,
    changeValueMediaList,
    mediaSelected,
    handlerMedias,
    editName,
    setEditName,
    editDescription,
    setEditDescription,
    handlerAvatarImage,
    avatarImage,
    handlerBannerImage,
    bannerImage,
    showAvatarImage,
    setShowAvatarImage,
    sizeAvatarImage,
    setSizeAvatarImage,
    showBannerImage,
    setShowBannerImage,
    sizeBannerImage,
    setSizeBannerImage,
    currentMediaEditIndex
  } = useStoreFront();

  const { isMd } = useScreenSize();

  return (
    <>
      <div className="mt-5">
        <SectionContent header="Título">
          <Row gap={16} align="flex-start" className="w-full h-[45px]">
            <div className="w-[90%]">
              <InputMedia
                changeEdit={editName ? () => setEditName(false) : () => setEditName(true)}
                placeholder="Nombre de usuario"
                value={name}
                name="name"
                changeValue={(e) => handlerName(e.target.value)}
                isEdit={editName}
                maxLength={60}
                fullWidth
                showCount
              />
            </div>
          </Row>
        </SectionContent>
      </div>

      <SectionContent header="Subtítulo">
        <Row gap={16} align="flex-start" className="h-[45px]">
          <div className="w-[90%]">
            <InputMedia
              placeholder="Descripción"
              value={description}
              name="description"
              changeEdit={editDescription ? () => setEditDescription(false) : () => setEditDescription(true)}
              changeValue={(e) => handlerDescription(e.target.value)}
              fullWidth
              maxLength={150}
              showCount
              isEdit={editDescription}
            />
          </div>
        </Row>
      </SectionContent>
      <SectionContent
        header={
          <Row align="center">
            <p className="mr-5">Imagen de perfil</p>

            <Toggle
              checked={showAvatarImage !== undefined && showAvatarImage !== null ? showAvatarImage : true}
              onChange={() => setShowAvatarImage(!showAvatarImage)}
              color={theme.colors.blue[500]}
            ></Toggle>
          </Row>
        }
      >
        {showAvatarImage && (
          <Row gap={10} align="flex-end">
            <div
              className={classNames({
                'border border-solid border-blue rounded-md': sizeAvatarImage !== SizeImageE.SMALL,
                'border border-solid border-white rounded-md': sizeAvatarImage === SizeImageE.SMALL
              })}
              onClick={() => setSizeAvatarImage(SizeImageE.BIG)}
            >
              <InputImage
                type={TypeImageInput.AVATAR}
                width={isMd ? '170px' : '137px'}
                height={isMd ? '170px' : '137px'}
                img={avatarImage}
                handleImg={handlerAvatarImage}
              />
            </div>
            <div
              className={classNames('', {
                'border border-solid border-blue rounded-md': sizeAvatarImage === SizeImageE.SMALL,
                'border border-solid border-white rounded-md': sizeAvatarImage !== SizeImageE.SMALL
              })}
              onClick={() => setSizeAvatarImage(SizeImageE.SMALL)}
            >
              <InputImage
                type={TypeImageInput.AVATAR}
                width={isMd ? '120px' : '99px'}
                height={isMd ? '120px' : '99px'}
                img={avatarImage}
                handleImg={handlerAvatarImage}
              />
            </div>
          </Row>
        )}
      </SectionContent>

      <SectionContent
        maxWidth={500}
        header={
          <Row align="center">
            <p className="mr-5">Imagen de cover</p>

            <Toggle
              checked={showBannerImage !== undefined && showBannerImage !== null ? showBannerImage : true}
              onChange={() => setShowBannerImage(!showBannerImage)}
              color={theme.colors.blue[500]}
            ></Toggle>
          </Row>
        }
      >
        {showBannerImage && (
          <Column gap={10}>
            <div
              className={classNames('w-full', {
                'border border-solid border-blue rounded-md': sizeBannerImage === SizeImageE.SMALL
              })}
              onClick={() => {
                setSizeBannerImage(SizeImageE.SMALL);
              }}
            >
              <InputImage
                type={TypeImageInput.BANNER}
                width={isMd ? '487px' : '100%'}
                height={isMd ? '128px' : '115px'}
                img={bannerImage}
                handleImg={handlerBannerImage}
              />
            </div>
            <div
              className={classNames('w-full', {
                'border border-solid border-blue rounded-md': sizeBannerImage !== SizeImageE.SMALL
              })}
              onClick={() => setSizeBannerImage(SizeImageE.BIG)}
            >
              <InputImage
                type={TypeImageInput.BANNER}
                width={isMd ? '487px' : '100%'}
                height={isMd ? '182px' : '145px'}
                img={bannerImage}
                handleImg={handlerBannerImage}
              />
            </div>
          </Column>
        )}
      </SectionContent>
      <SectionContent borderBottom={false}>
        <SectionSocialMedia
          currentEditIndex={currentMediaEditIndex}
          handlerEditMedia={handlerEditMedia}
          selectMediaHandler={selectMediaHandler}
          mediaSelected={mediaSelected}
          changeValueMediaList={changeValueMediaList}
          handlerMedias={handlerMedias}
        />
      </SectionContent>
    </>
  );
};
