import { Column } from '@/components';
import { scrollBarTW } from '@/components/App';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/Dialog';
import { mercado_pago } from '@/static/images';

import React from 'react';

type DialogMercadoPagoProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onClick: () => void;
};

export const DialogMercadoPago: React.FC<DialogMercadoPagoProps> = ({ show, setShow, onClick }) => {
  return (
    <Dialog open={show} onOpenChange={setShow}>
      <DialogContent>
        <DialogHeader className="pb-4 mb-4 border-b border-[#DACCE0]">
          <DialogClose />
          <DialogTitle>Conectá tu billetera</DialogTitle>
          <button className="text-sm font-medium text-[#0072FB]" onClick={onClick}>
            Conectar
          </button>
        </DialogHeader>
        <DialogDescription className="pb-4 mb-4 flex items-center gap-2 justify-center">
          <img className="w-[140px] aspect-auto object-contain" src={mercado_pago} />
        </DialogDescription>
        <Column gap={10} className={`max-h-60 overflow-y-auto my-4 ${scrollBarTW}`}>
          <p className="text-balance font-medium">La integración con Mercado Pago sólo se utilizará para:</p>
          <ul className="text-sm">
            <li>✔ Acreditar pagos de tus clientes</li>
            <li>✔ Debitar devoluciones a tus clientes</li>
            <li>✔ Asociar suscriptores para tus planes mensuales</li>
          </ul>
          <p className="mt-4 text-sm font-medium">Podrás desconectar tu billetera cuando lo desees.</p>
        </Column>
        <DialogFooter>
          <button className="rounded-[5px] h-12 bg-[#0072FB] text-white font-medium w-full" onClick={onClick}>
            Conectar
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
