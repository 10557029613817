declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
  }
}

import { SessionI } from '@/types/cyclone/requests';

export const updateIntercomUser = (session: SessionI, isMobile: boolean) => {
  if (!session || !window.Intercom) return;

  // .
  window.Intercom('update', {
    app_id: 'lt3sf6pd',
    user_id: session.id,
    email: session.email,
    phone: session.phone,
    name: `${session.first_name} ${session.last_name}`,
    created_at: session.created_at,
    subscription_name: session.is_venue ? 'venue' : session.vendor?.plan_name,
    username: session.vendor?.username,
    is_mobile: isMobile
  });
};

export const updateIntercomPhone = (phone: string) => {
  if (!window.Intercom) return;
  window.Intercom('update', {
    phone: phone
  });
};

export const sendIntercomEvent = (event: string) => {
  if (!window.Intercom) return;
  window.Intercom('trackEvent', event);
};
