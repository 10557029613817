import React, { useEffect, useRef, FunctionComponent, ChangeEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Icon, IconName } from '@/components';
import { faTrashAlt, faCheck, faGrip, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

interface InputMediaPropsI {
  nameIcon?: IconName;
  originalColor?: boolean;
  color?: string;
  placeholder?: string;
  value?: string;
  name: string;
  changeValue: ChangeEventHandler<HTMLInputElement>;
  draggable?: boolean;
  error?: string;
  pattern?: string;
  maxLength?: number;
  showCount?: boolean;
  fullWidth?: boolean;
  isEdit?: boolean;
  changeEdit?: () => void;
  deleteItem?: () => void;
  type?: 'text' | 'number';
  prefix?: string;
  className?: string;
  inputClassName?: string;
}

export const InputMedia: FunctionComponent<InputMediaPropsI> = ({
  nameIcon,
  color = '#000',
  placeholder = '',
  value = '',
  name,
  changeValue,
  draggable,
  pattern = '',
  error,
  maxLength,
  showCount,
  fullWidth,
  isEdit,
  changeEdit,
  deleteItem,
  type = 'text',
  prefix,
  className,
  inputClassName,
  originalColor
}) => {
  const editTimeoutRef = useRef<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && isEdit && changeEdit) {
        changeEdit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      if (editTimeoutRef.current) {
        clearTimeout(editTimeoutRef.current);
      }
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEdit, changeEdit]);

  const handleEdit = () => {
    if (changeEdit) {
      changeEdit();
      editTimeoutRef.current = setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleInputClick = () => {
    if (!isEdit && changeEdit) {
      changeEdit();
    }
  };

  return (
    <div className={classNames('mb-3', { 'w-full': fullWidth })}>
      <div
        className={classNames(
          'flex items-center border rounded-md bg-[#FAFAFA] h-12 px-4',
          { 'w-full': fullWidth },
          className
        )}
        onClick={handleInputClick}
      >
        <div className="flex items-center w-full h-full">
          {nameIcon && (
            <Icon
              name={nameIcon}
              width="22"
              height="22"
              color={color}
              originalColor={originalColor}
              marginRight="4px"
            />
          )}
          {prefix && <p className="ml-2 text-[#868686]">{prefix}</p>}
          <input
            type={type}
            ref={inputRef}
            autoFocus
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={changeValue}
            pattern={pattern}
            maxLength={maxLength}
            className={classNames('truncate rounded focus:outline-none', inputClassName, {
              'w-full h-full cursor-pointer': !prefix,
              'w-auto font-medium cursor-pointer': prefix,
              'cursor-text': isEdit,
              'cursor-pointer': !isEdit
            })}
            readOnly={!isEdit}
          />
        </div>
        {changeEdit && (
          <button
            className="w-[30px] h-[30px] min-w-[30px] bg-white rounded-full flex items-center justify-center ml-2 p-1"
            onClick={handleEdit}
          >
            <FontAwesomeIcon icon={isEdit ? faCheck : faPencilAlt} />
          </button>
        )}
        {deleteItem && (
          <button
            className="w-[30px] h-[30px] min-w-[30px] bg-white rounded-full flex items-center justify-center ml-2 p-1"
            onClick={deleteItem}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        )}
        {draggable && (
          <div className="ml-2 p-1 text-[#868686]">
            <FontAwesomeIcon icon={faGrip} />
          </div>
        )}
      </div>
      {error && <div className="text-red-500 text-xs mt-1">{error}</div>}
      {maxLength && showCount && (
        <div className="text-[#868686] text-xs ml-1 -mt-1">
          {value?.length || 0}/{maxLength}
        </div>
      )}
    </div>
  );
};
