import React from 'react';

export const data = [
  {
    id: 1,
    text: 'Página web personalizada',
    description: <>Creá tu página web en 10 minutos con tu info, fotos y todos tus links.</>,
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 2,
    text: 'Bancar',
    description: <>Herramienta para que tus clientes te envíen aportes / donaciones.</>,
    pro: {
      available: true,
      commission: 0
    },
    event: {
      available: true,
      commission: 0
    }
  },
  {
    id: 3,
    text: 'Venta de entradas',
    description: (
      <>
        ¿Organizás eventos? Vendé entradas desde tu propia página. Podés ofrecer distintos tipos de entrada
        con precio diferenciado y monitorear tus ventas en tiempo real.
      </>
    ),
    pro: {
      available: true,
      commission: 5
    },
    event: {
      available: true,
      commission: 5
    }
  },
  {
    id: 4,
    text: 'Servicios personalizados',
    description: (
      <>
        ¿Ofrecés clases, sesiones o turnos personalizados (1 a 1)? Ingresá la información de tus servicios y
        tu disponibilidad horaria.
        <br /> Cada cliente podrá reservar online, desde su teléfono o computadora.
      </>
    ),
    pro: {
      available: true,
      commission: 0
    },
    event: {
      available: false,
      commission: null
    }
  },
  {
    id: 5,
    text: 'Servicios grupales',
    description: (
      <>
        ¿Ofrecés clases o talleres con horarios semanales fijos? Ingresá la información de cada servicio y su
        disponibilidad semanal. Tus clientes podrán reservar su lugar hasta agotar los cupos.
      </>
    ),
    pro: {
      available: true,
      commission: 0
    },
    event: {
      available: false,
      commission: null
    }
  },
  {
    id: 6,
    text: 'Packs con descuentos',
    description: (
      <>
        Vendé packs de 4, 8 o 12 encuentros con descuento (opcional). Permití que reserven todos los
        encuentros de una vez.
      </>
    ),
    pro: {
      available: true,
      commission: 0
    },
    event: {
      available: false,
      commission: null
    }
  },
  {
    id: 7,
    text: 'Planes mensuales para tus clientes',
    description: (
      <>
        Armá tus propios planes o membresías mensuales. Podés incluir tus clases, sesiones o talleres en Ágora
        y combinarlos con acceso a contenido en otras plataformas.
      </>
    ),
    pro: {
      available: true,
      commission: 0
    },
    event: {
      available: false,
      commission: null
    }
  },
  {
    id: 8,
    text: 'Procesamiento de Pagos',
    description: (
      <>
        Seleccioná los medios de pago que querés aceptar en tu tienda. Mercado Pago, tarjetas de crédito y
        débito, transferencias, efectivo, etc.
      </>
    ),
    pro: {
      available: false,
      commission: null
    },
    event: {
      available: false,
      commission: null
    }
  },
  {
    id: 9,
    text: 'Conciliación de cuentas',
    description: (
      <>
        No pierdas más el tiempo revisando o tratando de recordar quienes pagaron, lo vas a poder ver resuelto
        entrando a tu cuenta (disponible sólo si conectaste tu billetera).
      </>
    ),
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 10,
    text: 'Sistema de reservas 24/7',
    description: (
      <>
        Olvídate del ida y vuelta por WhatsApp, ahora tus clientes pueden reservar o comprar entradas desde
        cualquier dispositivo con acceso a internet.
      </>
    ),
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 11,
    text: 'Agenda online',
    description: (
      <>
        Ingresá tus horarios laborales, los días y horarios de tus encuentros recurrentes o eventos. Tu agenda
        siempre disponible y actualizada en tiempo real con las reservas.
      </>
    ),
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 12,
    text: 'Recordatorios',
    description: <>Notificaciones y recordatorios de reservas o ventas para vos y tus clientes.</>,
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 13,
    text: 'Registro de clientes (CRM)',
    description: (
      <>
        Tus ventas o reservas, suscriptores y clientes en un sólo lugar. Un registro de todas las
        transacciones para que las revises cuando quieras.
      </>
    ),
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 14,
    text: 'Descarga de reportes',
    description: (
      <>Descarga el listado de clientes y/o transacciones a un excel para compartir o analizar en detalle.</>
    ),
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 15,
    text: 'Cupones de descuento',
    description: (
      <>Asigná un cupón de descuento al mail de tu cliente para que lo disfrute cuando compre en tu página.</>
    ),
    pro: {
      available: true,
      commission: null
    },
    event: {
      available: true,
      commission: null
    }
  },
  {
    id: 16,
    text: 'Atención personalizada',
    description: (
      <>
        Queremos ayudarte a crecer y gestionar tu negocio, por cualquier duda nos podés escribir por mail,
        WhatsApp o <span style={{ color: '#0072FF' }}>coordinar una videollamada.</span>
      </>
    ),
    pro: {
      available: false,
      commission: null
    },
    event: {
      available: false,
      commission: null
    }
  }
];
