import React, { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { Input, Row, Column } from '@/components';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { toast } from 'react-toastify';
import { stepTwoMockup } from '@/static/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faXmark } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useOnboarding } from '../contexts';
import { useWindowResize } from '@/hooks';
import { MIN_USERNAME_LENGTH } from '../utils';
import { InputImage } from '@/components/Input/InputImage';
import { TypeImageInput } from '@/components/ImageManagerV2';

export const SiteData: FunctionComponent = () => {
  useMixpanelTrackPage('Onboarding', { step: 'SiteData' });

  const { user, avatarImage, handleAvatarImage, username, usernameIsAvailable, control, errors } =
    useOnboarding();
  const { isMobileSize } = useWindowResize();

  const handleCopy = (value: string): void => {
    navigator.clipboard.writeText(value);
    toast.success('Enlace copiado con éxito');
  };

  return (
    <Row className="w-full max-h-[calc(100vh-48px)]">
      <Column
        className={classNames('gap-3', {
          'w-3/5 p-8 h-[calc(100vh-48px)]': !isMobileSize,
          'w-full py-3': isMobileSize
        })}
      >
        <Column
          className={classNames({
            'max-h-[calc(100vh-48px)] overflow-y-auto border-r-[#868686] scrollbar-thin scrollbar-thumb-[#CECECE] scrollbar-track-[#f5f5f5] scrollbar-thumb-rounded-full':
              !isMobileSize,
            'my-4': isMobileSize
          })}
        >
          <Column gap={2}>
            <h3 className="font-medium text-lg sm:text-xl">Titulo / nombre de tu negocio</h3>
            <p className="text-sm sm:text-base text-[#626262]">Este será el encabezado de tu sitio.</p>
            <Controller
              control={control}
              name="storefront_name"
              defaultValue={user?.vendor.vendor_storefront?.name || ''}
              render={({ field: { onChange, value } }) => (
                <div className="w-full sm:w-3/4 mt-2">
                  <Input
                    inputId="storefront_name"
                    onChange={onChange}
                    value={value || ''}
                    maxLength={60}
                    showCount
                    error={errors.storefront_name && (errors.storefront_name.message as string)}
                    placeholderInput="Ej: Instituto de Nutrición"
                  />
                </div>
              )}
            />
          </Column>
          <Column gap={2}>
            <h3 className="font-medium text-lg sm:text-xl">Subtítulo</h3>
            <p className="text-sm sm:text-base text-[#626262]">Frase o breve descripción de tu negocio.</p>
            <Controller
              control={control}
              name="profession"
              render={({ field: { onChange, value } }) => (
                <div className="w-full sm:w-3/4 mt-2">
                  <Input
                    inputId="profession"
                    onChange={onChange}
                    value={value || ''}
                    maxLength={150}
                    showCount
                    error={errors.profession && (errors.profession.message as string)}
                    placeholderInput="Ej: Nutricionista Deportivo"
                  />
                </div>
              )}
            />
          </Column>
          <Column gap={2}>
            <h3 className="font-medium text-lg sm:text-xl">Personalizá tu URL</h3>
            <p className="text-sm sm:text-base text-[#626262]">Este será el enlace de tu sitio</p>
            <Controller
              control={control}
              name="username"
              defaultValue={user?.vendor.username}
              render={({ field: { onChange, value } }) => (
                <>
                  <Row
                    align="center"
                    className="cursor-pointer w-fit mt-2"
                    gap={10}
                    onClick={() => handleCopy(`www.agora.red/${value}`)}
                  >
                    <p className="hover:underline underline-offset-1 text-xs text-[#0072FB]">{`www.agora.red/${value}`}</p>
                    <FontAwesomeIcon icon={faCopy} fontSizeAdjust={12} color="#0072FB" />
                  </Row>
                  <div className="w-full sm:w-1/2">
                    <Input
                      inputId="username"
                      onChange={onChange}
                      maxLength={20}
                      showCount
                      value={value || ''}
                      error={errors.username && (errors.username.message as string)}
                      helpText="Debe ser único y tener un mínimo de 5 caracteres."
                      postFix={
                        username && username.length > MIN_USERNAME_LENGTH ? (
                          <Row align="center" gap={5}>
                            <FontAwesomeIcon
                              icon={usernameIsAvailable ? faCheck : faXmark}
                              fontSizeAdjust={14}
                              color={usernameIsAvailable ? '#25D366' : '#FF4658'}
                              className="mt-1"
                            />
                            <p
                              className={classNames(
                                'text-sm',
                                { 'text-[#25D366]': usernameIsAvailable },
                                { 'text-[#FF4658]': !usernameIsAvailable }
                              )}
                            >
                              {usernameIsAvailable ? 'Disponible' : 'No disponible'}
                            </p>
                          </Row>
                        ) : (
                          ''
                        )
                      }
                    />
                  </div>
                </>
              )}
            />
          </Column>
          <Row align="center" className="mt-4 gap-8">
            <Column className="h-full">
              <h3 className="font-medium text-lg sm:text-xl">Foto de perfil</h3>
              <Column className="gap-2">
                <p className="text-sm sm:text-base text-[#626262] mt-4">
                  Agregá una foto de perfil o logo para tu negocio
                </p>
                <p className="text-xs text-[#626262]">Max. 2MB. Formato jpg, png. Recomendada (500x500) px</p>
              </Column>
            </Column>
            <InputImage
              type={TypeImageInput.AVATAR}
              width={'128px'}
              height={'128px'}
              img={avatarImage}
              handleImg={handleAvatarImage}
            />
          </Row>
        </Column>
      </Column>
      {!isMobileSize && (
        <Column gap={20} className="w-2/5 h-[calc(100vh-48px)] bg-[#FBFBFB] p-8">
          <div
            style={{
              backgroundImage: `url(${stepTwoMockup})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: '100%'
            }}
          />
        </Column>
      )}
    </Row>
  );
};
