import React, { ChangeEvent, FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

type VariantType = 'input' | 'textarea';

interface LinkInputPropsI {
  placeholder?: string;
  value?: string;
  name: string;
  onChange: ((e: ChangeEvent<HTMLInputElement>) => void) | ((e: ChangeEvent<HTMLTextAreaElement>) => void);
  error?: string;
  variant?: VariantType;
  id: string;
  maxLength?: number;
  showCount?: boolean;
}

export const LinkInput: FunctionComponent<LinkInputPropsI> = ({
  placeholder = '',
  value = '',
  name,
  onChange,
  error,
  variant = 'input',
  id,
  maxLength,
  showCount
}) => {
  const validateOnChange = (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
    if (e.target.validity.valid) onChange(e);
  };

  return (
    <Content>
      <InputContent variant={variant}>
        {variant === 'input' ? (
          <input
            type="text"
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            style={{ width: '100%' }}
            onChange={validateOnChange}
          />
        ) : (
          <textarea
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            style={{ width: '100%', height: 160 }}
            onChange={validateOnChange}
          />
        )}
      </InputContent>
      <Flex>
        {error ? (
          <BottomText error={!!error} showCount={showCount || false}>
            {error}
          </BottomText>
        ) : (
          <div></div>
        )}
        {maxLength && showCount && (
          <CountCharacters left={false}>
            {value?.length || 0}/{maxLength}
          </CountCharacters>
        )}
      </Flex>
    </Content>
  );
};

const Content = styled.div`
  margin-bottom: 24px;
`;

const InputContent = styled.div<{ variant: VariantType }>`
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5px;
  ${({ variant }) =>
    variant === 'input'
      ? `height: 45px;`
      : `
	padding-top: 10px;`};
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.4rem;
`;

const BottomText = styled.span<{ error: boolean; showCount: boolean }>`
  display: flex;
  flex-direction: row;
  min-width: 15rem;
  color: ${({ theme, error }) => (error ? theme.colors.red[200] : theme.colors.grey[700])};
  font-size: 0.73rem;
  margin-left: 1rem;
`;

const CountCharacters = styled.span<{ left: boolean }>`
  display: flex;
  color: ${({ theme }) => theme.colors.blue[500]};
  font-size: 0.73rem;
  ${({ left }) =>
    left &&
    css`
      width: 100%;
      justify-content: flex-end;
    `}
`;
