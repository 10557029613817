import { Icon, ModalV2, ModalHeader } from '@/components';
import { theme } from '@/components/App';
import { sad } from '@/static/images';
import {
  InvitationI,
  InvitationStatus as InvitationStatusModel,
  VendorStatusE
} from '@/types/cyclone/models';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

type InvitationsModalProps = {
  setShow: (show: boolean) => void;
  invitations: InvitationI[];
};

enum InvitationStatusE {
  INVITED = 'Invitado',
  FREE_TRIAL = 'Prueba',
  ACTIVE = 'Activo',
  CANCELLED = 'Cancelado'
}

export const InvitationsModal: FunctionComponent<InvitationsModalProps> = ({ setShow, invitations }) => {
  const getStatus = (invitation: InvitationI) => {
    const vendor = invitation?.user_invitee?.vendor;
    const vendorStatusHistory = vendor?.vendor_status_history?.[0];

    if (
      invitation.status === InvitationStatusModel.SENT ||
      vendorStatusHistory?.status === VendorStatusE.ONBOARDING
    ) {
      return InvitationStatusE.INVITED;
    } else if (vendorStatusHistory?.status === VendorStatusE.FREE_TRIAL) {
      return InvitationStatusE.FREE_TRIAL;
    } else if (
      vendorStatusHistory?.status === VendorStatusE.ACTIVE_AFTER_FREE_TRIAL ||
      vendorStatusHistory?.status === VendorStatusE.ACTIVE_AFTER_INACTIVE
    ) {
      return InvitationStatusE.ACTIVE;
    } else if (
      vendorStatusHistory?.status === VendorStatusE.CHURN ||
      vendorStatusHistory?.status === VendorStatusE.INACTIVE
    ) {
      return InvitationStatusE.CANCELLED;
    }
    return InvitationStatusE.INVITED;
  };

  const renderInvitationRow = () => {
    return invitations.map((invitation) => {
      const { id, user_invitee_email, user_invitee, created_at } = invitation;
      const statusTag = getStatus(invitation);
      const invitationDate = dayjs(created_at).format('DD/MM/YYYY');
      return (
        <InvitationRowContainer key={id}>
          <ProfileImageContainer>
            {user_invitee?.avatar_url ? (
              <Image src={user_invitee?.avatar_url} />
            ) : (
              <Icon
                name="user_circle"
                width="30px"
                height="30px"
                marginRight="15px"
                color={theme.colors.grey[700]}
              />
            )}
            <ActivityUser>
              {user_invitee_email}
              <Email>Invitado el {invitationDate}</Email>
            </ActivityUser>

            <div
              className={classNames({
                'px-2 py-1.5 rounded-lg font-bold': true,
                'text-gray-500 bg-gray-200': statusTag === InvitationStatusE.INVITED,
                'text-yellow-500 bg-yellow-100': statusTag === InvitationStatusE.FREE_TRIAL,
                'text-green-500 bg-green-200': statusTag === InvitationStatusE.ACTIVE,
                'text-red-500 bg-red-200': statusTag === InvitationStatusE.CANCELLED
              })}
            >
              {statusTag}
            </div>
          </ProfileImageContainer>
        </InvitationRowContainer>
      );
    });
  };
  return (
    <ModalV2 show onClose={() => setShow(false)}>
      <ModalHeader>Tus referidos</ModalHeader>
      <ModalContent>
        {invitations.length > 0 ? (
          renderInvitationRow()
        ) : (
          <EmptyState>
            <img src={sad} width={45} height={45} />
            <p>Todavía no enviaste ninguna invitación</p>
          </EmptyState>
        )}
      </ModalContent>
    </ModalV2>
  );
};

const ModalContent = styled.div`
  flex: 1;
  flex-direction: column;
`;

const EmptyState = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0px 100px;
  p {
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey[700]};
    margin-top: 22px;
  }
`;

export const ProfileImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
`;

export const Image = styled.img`
  background-color: red;
  border-radius: 50%;
  height: 30px;
  margin-right: 16px;
  width: 30px;
  object-position: center;
  object-fit: cover;

  ${({ theme }) => theme.breakpoint('sm')} {
    height: 20px;
    margin-right: 8px;
    width: 20px;
  }
`;

export const ActivityUser = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Email = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.grey[700]};
  ${({ theme }) => theme.breakpoint('md')} {
    font-size: 11px;
  }
`;

export const InvitationRowContainer = styled.div`
  padding: 8px 0px;
`;

export const InvitationStatusTag = styled.div<{ status: InvitationStatusModel }>`
  color: ${({ status }) =>
    status === InvitationStatusModel.ACCEPTED ? theme.colors.green[200] : theme.colors.grey[700]};
  background-color: ${({ status }) =>
    status === InvitationStatusModel.ACCEPTED ? theme.colors.blue[200] : theme.colors.grey[400]};
  padding: 4px 6px;
  border-radius: 5px;
  font-weight: 600;
`;
