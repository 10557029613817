import { Column, Row } from '@/components';
import { useAuth } from '@/contexts';
import { UserI, VendorClientsBaseI } from '@/types/cyclone/models';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const handleCopy = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.success('Email copiado al portapapeles', {
    position: 'bottom-left'
  });
};

export const GetUserName = ({
  clients,
  onClick,
  user,
  isDialog
}: {
  clients: VendorClientsBaseI[];
  onClick: (text: string) => void;
  user?: UserI;
  isDialog?: boolean;
}) => {
  const [copy, setCopy] = React.useState(false);
  const { session } = useAuth();
  const fullName = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;
  const trimmedFullName = fullName.length > 22 ? `${fullName.substring(0, 22)}...` : fullName;
  const clientId = clients.find((_client) => _client.user_id === user?.id)?.id;
  const email = user && user?.email;

  const handleOnClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>, email: string) => {
    onClick(email);
    setCopy(true);
    e.stopPropagation();

    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  if (user?.first_name && user?.last_name) {
    return (
      <>
        {!session?.is_staff ? (
          <Link
            to={`/clientes/${clientId}`}
            target="_blank"
            className={classNames(
              'text-sm !leading-none hover:underline underline-offset-2 cursor-pointer w-fit transition-all duration-200',
              {
                'font-medium text-base sm:text-lg': isDialog
              }
            )}
          >
            {trimmedFullName}
          </Link>
        ) : (
          <span
            className={classNames('text-sm !leading-none cursor-pointer w-fit transition-all duration-200', {
              'font-medium text-base sm:text-lg': isDialog
            })}
          >
            {trimmedFullName}
          </span>
        )}
        {email && (
          <Row
            align="center"
            gap={5}
            className="w-fit sm:hover:underline cursor-pointer underline-offset-2 text-gray-400 transition-all duration-200"
            onClick={(e) => handleOnClick(e, email)}
          >
            <span className={classNames('text-xs', { 'text-xs sm:text-sm': isDialog })}>{email}</span>
            <FontAwesomeIcon
              icon={copy ? faCheck : faCopy}
              fontSizeAdjust={12}
              className={classNames({ 'text-[#09D5A1]': copy })}
            />
          </Row>
        )}
      </>
    );
  } else {
    if (email)
      return (
        <Row
          align="center"
          gap={5}
          className="sm:hover:underline w-fit cursor-pointer underline-offset-2 transition-all duration-200"
          onClick={(e) => handleOnClick(e, email)}
        >
          <span
            className={classNames('text-sm cursor-pointer w-fit transition-all duration-200', {
              'font-medium text-base sm:text-lg': isDialog
            })}
          >
            {email}
          </span>
          <FontAwesomeIcon
            icon={copy ? faCheck : faCopy}
            fontSizeAdjust={12}
            className={classNames('-mb-0.5', { 'text-[#09D5A1]': copy })}
          />
        </Row>
      );
  }
  return (
    <span
      className={classNames('text-sm cursor-pointer w-fit transition-all duration-200', {
        'font-medium text-base sm:text-lg': isDialog
      })}
    >
      Anónimo
    </span>
  );
};

export type StatusType = {
  name: string;
  color: string;
};

export const bookingStatusRecord: Record<string, StatusType> = {
  confirmed: {
    name: 'Confirmada',
    color: '#09D5A1'
  },
  canceled: {
    name: 'Cancelada',
    color: '#FF4658'
  },
  refunded: {
    name: 'Devolución',
    color: '#FF4658'
  },
  pending: {
    name: 'Pendiente',
    color: '#DEC142'
  }
};

export const transactionStatusRecord: Record<string, StatusType> = {
  confirmed: {
    name: 'Pago',
    color: '#09D5A1'
  },
  pending: {
    name: 'Pendiente',
    color: '#DEC142'
  },
  refund: {
    name: 'Devolución',
    color: '#FF4658'
  },
  down_payment_pending: {
    name: 'Seña pendiente',
    color: '#DEC142'
  },
  down_payment_confirmed: {
    name: 'Pago total pendiente',
    color: '#DEC142'
  }
};

export const formatDateJSX = (date: any, time: any) => {
  return (
    <Column>
      <span className="text-sm">{date}</span>
      <span className="text-xs text-gray-400">{time} hs</span>
    </Column>
  );
};
