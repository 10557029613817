import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { PricingPlans, Spinner } from '@/components';
import { EmptyObject, ErrorI, GetPlansI, PutPlanI } from '@/types/cyclone/requests';
import { useClient, useWindowResize } from '@/hooks';
import { useAuth, useConfirmation } from '@/contexts';
import { AgoraPlanNameE } from '@/types/cyclone/models';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { TablePlans } from '../SelfOnboarding/components/TablePlans';
import { TablePlansMobile } from '../SelfOnboarding/components/TablePlansMobile';

export const Plans: FunctionComponent = () => {
  useMixpanelTrackPage('Plans');
  const navigate = useNavigate();
  const { client } = useClient();
  const { session, reloadSession } = useAuth();
  const { confirm } = useConfirmation();
  const { isMobileSize } = useWindowResize(900);

  const mutation = useMutation<EmptyObject, ErrorI, PutPlanI>(
    (data) =>
      client<EmptyObject>(`me/vendor/plans/${session?.active_plan.id}`, 'PUT', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        reloadSession();
        navigate('/facturacion');
        setTimeout(() => window.location.reload(), 2000);
      }
    }
  );

  const { data: plansData } = useQuery(
    ['plans'],
    async () =>
      await client<GetPlansI>(`plans`, 'GET', {
        isAuthRequired: true
      }),
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );

  const getPlanName = (planId: number) => {
    switch (planId) {
      case 2:
        return AgoraPlanNameE.EVENT;
      case 3:
        return AgoraPlanNameE.PROFESSIONAL;
      default:
        throw new Error('Plan name not found');
    }
  };

  const handleSubmit = (planId: number) => {
    const commonConfirmationFields = {
      confirmText: 'Cambiar plan',
      cancelText: 'Mantener plan',
      onConfirm: () => onConfirmPlanChange(planId)
    };

    // Events -> Free
    if (session?.vendor?.plan_name === AgoraPlanNameE.EVENT && planId === 1) {
      return confirm({
        ...commonConfirmationFields,
        status: 'info',
        content: `No podrás vender más eventos, tus eventos creados no serán visibles para tus clientes.`,
        title: '¿Estás seguro que deseás cambiar de plan?'
      });
    }

    // Pro -> Free
    if (session?.vendor?.plan_name === AgoraPlanNameE.PROFESSIONAL && planId === 1) {
      return confirm({
        ...commonConfirmationFields,
        status: 'info',
        content: `Podras seguir utilizando los beneficios de este plan hasta el ${
          session?.active_plan?.payment_date
        }. Luego de esa fecha pasarás al plan ${getPlanName(
          planId
        )}.  No podrás vender servicios 1 a 1, eventos grupales recurrentes o planes mensuales, tus servicios y planes mensuales se pausarán (no serán visibles para tus clientes).`,
        title: '¿Estás seguro que deseás cambiar de plan?'
      });
    }

    // Pro -> Eventos
    if (session?.vendor?.plan_name === AgoraPlanNameE.PROFESSIONAL && planId === 2) {
      return confirm({
        ...commonConfirmationFields,
        status: 'info',
        content: `Podras seguir utilizando los beneficios de este plan hasta el ${
          session?.active_plan?.payment_date
        }. Luego de esa fecha pasarás al plan ${getPlanName(
          planId
        )}.  No podrás vender servicios 1 a 1, eventos grupales recurrentes o planes mensuales, tus servicios y planes mensuales se pausarán (no serán visibles para tus clientes).`,
        title: '¿Estás seguro que deseás cambiar de plan?'
      });
    }

    // // Free || Events -> Pro
    // if (
    //   session?.vendor?.plan_name === AgoraPlanNameE.EVENT ||
    //   (session?.vendor?.plan_name === AgoraPlanNameE.FREE && planId === 3)
    // ) {
    //   return confirm({
    //     ...commonConfirmationFields,
    //     status: 'info',
    //     content: `El plan seleccionado se activará de manera inmediata luego de la confirmación.
    //     Los costos del nuevo plan se verán reflejados en la factura del próximo período.`,
    //     title: '¿Deseás cambiar tu plan?'
    //   });
    // }

    return confirm({
      ...commonConfirmationFields,
      status: 'info',
      content: '¿Estas seguro de querer cambiar de plan? Este proceso puede llevar un par de segundos',
      title: 'Cambio de plan'
    });
  };

  const onConfirmPlanChange = (planId: number) => {
    const data: PutPlanI = {
      plan_id: planId,
      recurrence: 'monthly'
    };
    mutation.mutate(data);
  };

  if (!plansData || !session)
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );

  return (
    <Container>
      <PricingPlans
        isLoading={mutation.isLoading}
        onSubmit={handleSubmit}
        country={session.vendor?.country}
      />
      {!isMobileSize ? <TablePlans onSubmit={handleSubmit} /> : <TablePlansMobile />}
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  max-width: 1536px;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
`;

const SpinnerContainer = styled.div`
  margin-top: 2rem;
`;
