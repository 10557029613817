import { NavbarItem } from '@/components/Navbar';
import { useAuth } from '@/contexts';
import { LINKS } from '@/constants';
import { AgoraPlanNameE } from '@/types/cyclone/models';
import { trackGenericEvent } from '@/analytics';
import {
  faGear,
  faMobileAlt,
  faPeopleGroup,
  faSignOutAlt,
  faStoreAlt,
  faTicket,
  faWallet
} from '@fortawesome/free-solid-svg-icons';
import { faAddressBook, faCalendar, faChartBar, faCreditCard } from '@fortawesome/free-regular-svg-icons';

export const useNavbarItems = (
  path?: string
): { mainItems: NavbarItem[]; dropdownItems: NavbarItem[]; footerItems: NavbarItem[] } => {
  const { logOut, session } = useAuth();

  const mainItems: NavbarItem[] = !session?.is_staff
    ? [
        {
          label: 'Mi página',
          onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Sitebuilder' }),
          isSelected: path === '/mi-pagina',
          link: '/mi-pagina',
          icon: faMobileAlt
        }
      ]
    : [];

  if (session?.vendor?.plan_name === AgoraPlanNameE.PROFESSIONAL && !session.is_staff) {
    if (!session.is_venue) {
      mainItems.push({
        label: 'Catálogo',
        onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Services' }),
        isSelected:
          path === '/servicios' ||
          path === '/planesmensuales' ||
          path === '/eventos' ||
          path === '/descargables' ||
          path === '/bancar' ||
          path === '/dashboard',
        isDropdown: true,
        link: '',
        icon: faStoreAlt,
        dropdownContent: [
          {
            label: 'Servicios',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Services' }),
            isSelected: path === '/servicios',
            link: '/servicios'
          },
          {
            label: 'Planes mensuales',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Subscriptions' }),
            isSelected: path === '/planesmensuales',
            link: '/planesmensuales'
          },
          {
            label: 'Eventos',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Events' }),
            isSelected: path === '/eventos',
            link: '/eventos'
          },
          {
            label: 'Descargables',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Downloadables' }),
            isSelected: path === '/descargables',
            link: '/descargables'
          },
          {
            label: 'Bancar',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Bancar' }),
            isSelected: path === '/bancar',
            link: '/bancar'
          }
          /*  {
            label: 'Analítica de negocio',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Analytics' }),
            isSelected: path === '/analytics',
            link: '/analytics'
          } */
          // {
          //   label: 'Panel general',
          //   onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Dashboard' }),
          //   isSelected: path === '/dashboard',
          //   link: '/dashboard'
          // }
        ]
      });
    } else {
      mainItems.push({
        label: 'Catálogo',
        onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Services' }),
        isSelected:
          path === '/servicios' ||
          path === '/planesmensuales' ||
          path === '/eventos' ||
          path === '/descargables' ||
          path === '/bancar' ||
          path === '/dashboard',
        isDropdown: true,
        icon: faStoreAlt,
        link: '',
        dropdownContent: [
          {
            label: 'Servicios',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Services' }),
            isSelected: path === '/servicios',
            link: '/servicios'
          },
          {
            label: 'Eventos',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Events' }),
            isSelected: path === '/eventos',
            link: '/eventos'
          },
          {
            label: 'Descargables',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Downloadables' }),
            isSelected: path === '/descargables',
            link: '/descargables'
          },
          {
            label: 'Bancar',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Bancar' }),
            isSelected: path === '/bancar',
            link: '/bancar'
          }
          // {
          //   label: 'Panel general',
          //   onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Dashboard' }),
          //   isSelected: path === '/dashboard',
          //   link: '/dashboard'
          // }
        ]
      });
      mainItems.push({
        label: 'Equipo',
        onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Team' }),
        isSelected: path === '/equipo',
        link: '/equipo',
        icon: faPeopleGroup
      });
    }
    mainItems.push({
      label: 'Agenda',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Agenda' }),
      isSelected: path === '/agenda',
      link: '/agenda',
      icon: faCalendar
    });
    mainItems.push({
      label: 'Actividad',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Activity' }),
      isSelected: path === '/actividad',
      link: '/actividad',
      icon: faChartBar
    });
    mainItems.push({
      label: 'Clientes',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Clientes' }),
      isSelected: path === '/clientes',
      link: '/clientes',
      icon: faAddressBook
    });
    mainItems.push({
      label: 'Descuentos',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Descuentos' }),
      isSelected: path === '/descuentos',
      link: '/descuentos',
      icon: faTicket
    });
  }
  if (session?.vendor?.plan_name === AgoraPlanNameE.EVENT && !session.is_staff) {
    mainItems.push({
      label: 'Catálogo',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Business Dropdown' }),
      isSelected:
        path === '/servicios' ||
        path === '/planesmensuales' ||
        path === '/eventos' ||
        path === '/descargables' ||
        path === '/bancar' ||
        path === '/dashboard',
      isDropdown: true,
      link: '',

      dropdownContent: [
        {
          label: 'Eventos',
          onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Events' }),
          isSelected: path === '/eventos',
          link: '/eventos'
        },
        {
          label: 'Descargables',
          onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Downloadables' }),
          isSelected: path === '/descargables',
          link: '/descargables'
        }
        // {
        //   label: 'Panel general',
        //   onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Dashboard' }),
        //   isSelected: path === '/dashboard',
        //   link: '/dashboard'
        // }
      ]
    });
    mainItems.push({
      label: 'Promotores',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Promotores' }),
      isSelected: path === '/promotores',
      link: '/promotores'
    });
    mainItems.push({
      label: 'Actividad',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Activity' }),
      isSelected: path === '/actividad',

      link: '/actividad'
    });
    mainItems.push({
      label: 'Clientes',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Clientes' }),
      isSelected: path === '/clientes',
      link: '/clientes'
    });
    mainItems.push({
      label: 'Descuentos',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Descuentos' }),
      isSelected: path === '/descuentos',
      link: '/descuentos'
    });
  }

  if (session?.is_staff) {
    mainItems.push({
      label: 'Agenda',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Agenda' }),
      isSelected: path === '/agenda',
      link: '/agenda'
    });
    mainItems.push({
      label: 'Actividad',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Activity' }),
      isSelected: path === '/actividad',
      link: '/actividad'
    });
  }

  const dropdownItems: NavbarItem[] =
    session?.vendor?.plan_name === AgoraPlanNameE.PROFESSIONAL
      ? session.is_venue && !session.is_staff
        ? [
            {
              label: 'Referidos',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Referrals' }),
              isSelected: path === '/referidos',
              link: '/referidos',
              icon: faPeopleGroup
            },
            {
              label: 'Ajustes',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Settings' }),
              isSelected: path === '/ajustes',
              link: '/ajustes',
              icon: faGear
            },
            {
              label: 'Mi plan',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'My plan' }),
              isSelected: path === '/facturacion',
              link: '/facturacion',
              icon: faCreditCard
            },
            {
              label: 'Medios de pago',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Payment Gateways' }),
              isSelected: path === '/ajustes/medios-de-pago',
              link: '/ajustes/medios-de-pago',
              icon: faWallet
            },
            {
              label: 'Salir',
              onClick: () => {
                logOut();
              },
              isSelected: path === '/ajustes',
              link: '',
              icon: faSignOutAlt
            }
          ]
        : session.is_staff
        ? [
            {
              label: 'Salir',
              onClick: () => {
                logOut();
              },
              isSelected: path === '/ajustes',
              link: '',
              icon: faSignOutAlt
            }
          ]
        : [
            {
              label: 'Referidos',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Referrals' }),
              isSelected: path === '/referidos',
              link: '/referidos',
              icon: faPeopleGroup
            },
            {
              label: 'Ajustes',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Settings' }),
              isSelected: path === '/ajustes',
              link: '/ajustes',
              icon: faGear
            },
            {
              label: 'Mi plan',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'My plan' }),
              isSelected: path === '/facturacion',
              link: '/facturacion',
              icon: faCreditCard
            },
            {
              label: 'Medios de pago',
              onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Payment Gateways' }),
              isSelected: path === '/ajustes/medios-de-pago',
              link: '/ajustes/medios-de-pago',
              icon: faWallet
            },
            {
              label: 'Salir',
              onClick: () => {
                logOut();
              },
              isSelected: path === '/ajustes',
              link: '',
              icon: faSignOutAlt
            }
          ]
      : [
          {
            label: 'Invitaciones',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Referrals' }),
            isSelected: path === '/referidos',
            link: '/referidos',
            icon: faPeopleGroup
          },
          {
            label: 'Ajustes',
            onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Settings' }),
            isSelected: path === '/ajustes',
            link: '/ajustes',
            icon: faGear
          },
          {
            label: 'Salir',
            onClick: () => {
              logOut();
            },
            isSelected: path === '/ajustes',
            link: '',
            icon: faSignOutAlt
          }
        ];

  const footerItems: NavbarItem[] = [
    {
      label: 'Soporte Técnico',
      link: `https://api.whatsapp.com/send/?phone=541136535645`,
      onClick: () => {
        trackGenericEvent('Footer Link Clicked', { name: 'Support' });
      }
    },
    {
      label: 'Preguntas frecuentes',
      link: 'https://ayuda.agora.red/es/collections/4030470-preguntas-frecuentes',
      onClick: () => {
        trackGenericEvent('Footer Link Clicked', { name: 'FAQ' });
      }
    },
    {
      label: 'Términos y condiciones',
      link: LINKS.TERMS,
      onClick: () => {
        trackGenericEvent('Footer Link Clicked', { name: 'Terms & Conditions' });
      }
    },
    {
      label: 'Política de privacidad',
      link: LINKS.PRIVACY,
      onClick: () => {
        trackGenericEvent('Footer Link Clicked', { name: 'Privacy Policy' });
      }
    }
  ];

  return { mainItems, dropdownItems, footerItems };
};
