import { Icon } from '@/components';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface PlanCharacteristicsPropsI {
  text: string;
  description: ReactNode;
}

export const PlanCharacteristics: FunctionComponent<PlanCharacteristicsPropsI> = ({ text, description }) => {
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState('0px');
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      setMaxHeight(ref.current ? `${ref.current.scrollHeight}px` : '0px');
    } else {
      setMaxHeight('0px');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div onClick={() => setOpen(!open)}>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between ' }}>
          <Text>{text}</Text> <CustomIcon name="chevron_down" active={open} color="#757575" />
        </div>
        <ContentContainer ref={ref} maxHeight={maxHeight}>
          <TextDescription>{description}</TextDescription>
        </ContentContainer>
      </div>
    </div>
  );
};

const CustomIcon = styled(Icon)<{ active: boolean }>`
  transition: transform 0.2s ease;
  transform: ${({ active }) => (active ? 'rotate(-180deg)' : 'rotate(0deg)')};
  width: 10px;
  height: 17px;
  ${({ theme }) => theme.breakpoint('lg')} {
    width: 13px;
    height: 13px;
  }
`;
const ContentContainer = styled.div<{ maxHeight: string }>`
  max-height: ${({ maxHeight }) => maxHeight};
  overflow: hidden;
  transition: max-height 0.2s ease;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #757575;
`;

const TextDescription = styled.p`
  font-size: 15px;
  line-height: 18px;
  color: #757575;
  margin-top: 20px;
`;
