import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem
} from '@/components/DropdownMenu';
import classNames from 'classnames';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import {
  faExclamationTriangle,
  faInfo,
  faListCheck,
  faThumbTack,
  faWarning
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '@/contexts';
import {
  AgoraPlanNameE,
  UniqueEventStatusE,
  VendorAgoraPlanSubscriptionStatusE
} from '@/types/cyclone/models';
import dayjs from 'dayjs';
import { isBetweenDates } from '@/utils/schedule';
import { ConfirmationModal } from '@/components/ConfirmationModal';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useMutation, useQueryClient } from 'react-query';
import { EmptyObject, ErrorI } from '@/types/cyclone/requests';
import { toast } from 'react-toastify';
import { useClient } from '@/hooks';

type NotificationItem = {
  id: string;
  message: string;
  cta?: string;
  link: string;
  type: 'info' | 'warning' | 'important';
  show?: boolean;
  onClick?: () => void;
  isRead: boolean;
};

const TODAY_DATE = new Date();

export const Notifications: React.FC = () => {
  const { session } = useAuth();
  const { client } = useClient();
  const path = useLocation().pathname;
  const queryClient = useQueryClient();

  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  const [readNotifications, setReadNotifications] = useState<string[]>([]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showUpgradePlanAlert, setShowUpgradePlanAlert] = useState(false);
  const [showConnectWalletAlert, setShowConnectWalletAlert] = useState(false);
  const [showWaitingForGatewayAlert, setShowWaitingForGatewayAlert] = useState(false);
  const [showPendingAssetsUpgradeAlert, setShowPendingAssetsUpgradeAlert] = useState(false); // DEPRECATED
  const [showUpcomingEventAlert, setShowUpcomingEventAlert] = useState(false);
  const [showUnpaidPlanAlert, setShowUnpaidPlanAlert] = useState(false);

  React.useEffect(() => {
    const storedReadNotifications = localStorage.getItem('readNotifications');
    if (storedReadNotifications) {
      setReadNotifications(JSON.parse(storedReadNotifications));
    }
  }, []);

  const isWalletAssociated = session?.vendor?.payment_gateways
    ? session.vendor.payment_gateways.length !== 0
    : false;

  React.useEffect(() => {
    setShowPendingAssetsUpgradeAlert(false); // DEPRECATED
    if (
      session?.active_plan?.is_free_trial_exceeded &&
      session?.active_plan?.status !== VendorAgoraPlanSubscriptionStatusE.WAITING_FOR_GATEWAY &&
      session?.active_plan?.is_plan_available
    ) {
      setShowUpgradePlanAlert(true);
    } else {
      setShowUpgradePlanAlert(false);
    }

    if (session?.active_plan && !session?.active_plan?.is_plan_available) {
      setShowUnpaidPlanAlert(true);
    } else {
      setShowUnpaidPlanAlert(false);
    }

    if (session?.active_plan?.status === VendorAgoraPlanSubscriptionStatusE.WAITING_FOR_GATEWAY) {
      setShowWaitingForGatewayAlert(true);
    } else {
      setShowWaitingForGatewayAlert(false);
    }

    if (
      !isWalletAssociated &&
      !session?.vendor?.is_payment_bank_transfer_enabled &&
      !session?.vendor?.is_payment_cash_enabled
    ) {
      setShowConnectWalletAlert(true);
    } else {
      setShowConnectWalletAlert(false);
    }

    if (session?.has_events) {
      const upcomingEvent = session?.vendor?.unique_events?.find((event) => {
        // ! HARDCODED schedule, we assume it's not multidate
        const schedule = event.unique_events_schedules[0];

        if (schedule)
          return isBetweenDates(
            dayjs(schedule.start_at).toDate(),
            TODAY_DATE,
            dayjs(TODAY_DATE).add(2, 'day').toDate()
          );
        else return false;
      });
      if (upcomingEvent) {
        const shouldShowAlert = upcomingEvent.status === UniqueEventStatusE.ACTIVE;
        setShowUpcomingEventAlert(shouldShowAlert);
      }
    }

    const unsubscribe = queryClient.getQueryCache().subscribe(() => {});

    return () => {
      unsubscribe();
    };
  }, [path, session, queryClient]);

  const getAssets = () => {
    const assets = [];

    if (
      session?.vendor?.plan_name === AgoraPlanNameE.EVENT &&
      (session?.has_services || session?.has_subscriptions)
    ) {
      if (session?.has_services) assets.push('servicios');
      if (session?.has_subscriptions) assets.push('plan mensual');
    }
    return assets;
  };

  const renderShowUpcomingEventAlert = (): NotificationItem => {
    const events = session?.vendor?.unique_events
      ?.filter((event) => event.status === UniqueEventStatusE.ACTIVE)
      .sort((a, b) => {
        const aFirstSchedule = a.unique_events_schedules?.[0]?.start_at;
        const bFirstSchedule = b.unique_events_schedules?.[0]?.start_at;

        if (!aFirstSchedule || !bFirstSchedule) {
          return 0;
        }

        return aFirstSchedule > bFirstSchedule ? 1 : -1;
      });

    const nextEvent = events?.[0];

    const schedule = nextEvent?.unique_events_schedules[0];
    const isToday = schedule?.start_at === TODAY_DATE;

    const message = isToday
      ? '¡Hoy es tu evento! Escaneá las entradas'
      : '¡Falta poco para tu evento! Mirá los participantes';

    const link = isToday
      ? `/eventos/${nextEvent?.id}/registro`
      : `/eventos/${nextEvent?.id}/editar/participantes`;

    return {
      id: '5',
      message: `${message}`,
      cta: 'acá',
      link: link,
      show: showUpcomingEventAlert,
      type: 'info',
      isRead: readNotifications.includes('5')
    };
  };

  const notifications: NotificationItem[] = [
    {
      id: '1',
      message:
        'Alcanzaste tus 14 dias de prueba. Aboná tu Plan Profesional para continuar vendiendo sin límites.',
      cta: 'Aboná aca',
      link: '',
      show: showUpgradePlanAlert,
      type: 'info',
      onClick: () => setShowConfirmation(true),
      isRead: readNotifications.includes('1')
    },
    {
      id: '2',
      message: 'Tu plan Profesional está inactivo por falta de pago.',
      cta: 'Reactivalo acá o contactanos por cualquier duda.',
      link: '',
      onClick: () => setShowConfirmation(true),
      type: 'warning',
      show: showUnpaidPlanAlert,
      isRead: readNotifications.includes('2')
    },
    {
      id: '3',
      message: 'Para que tus servicios puedan ser contratados, tenés que habilitar tus medios de pago.',
      cta: 'Ir ahora',
      link: '/ajustes/medios-de-pago',
      show: showConnectWalletAlert,
      type: 'important',
      isRead: false //Es importante siempre estara en false
    },
    {
      id: '4',
      message: `Para poder vender ${getAssets().join(', ')} necesitas mejorar tu plan,`,
      cta: 'elegí el tuyo acá',
      link: '/planes',
      show: showPendingAssetsUpgradeAlert,
      type: 'important',
      isRead: false //Es importante siempre estara en false
    },
    renderShowUpcomingEventAlert(),
    {
      id: '6',
      message: 'Estamos esperando respuesta de Mercado Pago, podras acceder a nuestros servicios',
      link: '',
      show: showWaitingForGatewayAlert,
      type: 'warning',
      isRead: readNotifications.includes('6')
    }
  ];

  const handleRead = (id: string) => {
    const updatedReadNotifications = [...readNotifications, id];
    setReadNotifications(updatedReadNotifications);
    localStorage.setItem('readNotifications', JSON.stringify(updatedReadNotifications));
  };

  const unreadCount =
    notifications.filter((notification) => notification.show && !notification.isRead).length || 0;

  const confirmationItems = {
    title: (
      <>
        <p>Instrucciones para el pago:</p>
      </>
    ),
    content: (
      <Column justify="flex-start" className="p-2">
        <Row className="text-left">Transferir al alias: AGORA.ARG</Row>
        <Row className="text-left pt-2">
          Notificar luego de haber realizado la transferencia mediante el botón debajo
        </Row>
        <Row className="text-left pt-2">
          Por cualquier duda nos pueden escribir por WhatsApp al{' '}
          <a href="https://api.whatsapp.com/send/?phone=541160204078" target="_blank" rel="noreferrer">
            11 6020-4078
          </a>
        </Row>
      </Column>
    ),
    confirmText: 'Notificar'
  };

  const mutation = useMutation<EmptyObject, ErrorI>(
    () =>
      client<EmptyObject>(`me/vendor/plans/notify`, 'POST', {
        isAuthRequired: true
      }),
    {
      onSuccess: () => {
        toast.success('Se ha notificado exitosamente al equipo.');
      }
    }
  );

  return (
    <>
      <DropdownMenu open={showDropdownMenu} onOpenChange={(open) => setShowDropdownMenu(open)}>
        <DropdownMenuTrigger onClick={() => setShowDropdownMenu(!showDropdownMenu)}>
          <button
            className={classNames(
              'font-book text-sm transition-colors duration-200 relative hover:text-blue',
              {
                'text-blue': showDropdownMenu,
                'text-gray-700': !showDropdownMenu
              }
            )}
          >
            <FontAwesomeIcon icon={faBell} size="lg" />
            {unreadCount > 0 && (
              <span className="absolute top-0 right-0 inline-block w-2 h-2 text-xs font-semibold text-white bg-red-500 rounded-full text-center" />
            )}
          </button>
        </DropdownMenuTrigger>

        <DropdownMenuContent
          side="bottom"
          sideOffset={10}
          align="end"
          className="flex flex-col w-[100vw] sm:w-96 bg-white border border-gray-200 sm:rounded-lg shadow-lg !p-0"
        >
          <div className="px-4 py-2 text-lg font-semibold text-gray-800">Notificaciones</div>
          <ul className="min-h-60 max-h-60 overflow-y-auto">
            {notifications.filter((notification) => notification.show).length > 0 ? (
              notifications
                .sort((a, b) => (a.type === 'important' ? -1 : b.type === 'important' ? 1 : 0))
                .filter((notification) => notification.show)
                .map((notification) => {
                  const getIcon = () => {
                    switch (notification.type) {
                      case 'info':
                        return (
                          <div className="w-8 h-8 bg-blue/30 flex items-center justify-center rounded-full self-start">
                            <FontAwesomeIcon icon={faInfo} size="lg" className="text-blue/50" />
                          </div>
                        );
                      case 'warning':
                        return (
                          <div className="w-8 h-8 bg-[#F9E8B4] flex items-center justify-center rounded-full self-start">
                            <FontAwesomeIcon
                              icon={faWarning}
                              size="lg"
                              className="text-[#F1CE5E]"
                              fixedWidth
                            />
                          </div>
                        );
                      case 'important':
                        return (
                          <div className="relative w-8 h-8 bg-[#FAC7C7] flex items-center justify-center rounded-full self-center">
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              size="lg"
                              fixedWidth
                              className="text-[#F58585]"
                            />
                            <FontAwesomeIcon
                              icon={faThumbTack}
                              size="sm"
                              className="absolute -top-1 -rotate-12 left-0 text-[#F58585]"
                            />
                          </div>
                        );
                    }
                  };
                  return (
                    <DropdownMenuItem
                      key={notification.id}
                      className={classNames(
                        'cursor-pointer hover:bg-gray-100 focus:bg-transparent px-4 py-2 min-h-[70px]',
                        {
                          'bg-red-50 hover:bg-red-100 focus:bg-red-50': notification.type === 'important'
                        }
                      )}
                    >
                      <Link
                        to={notification.link}
                        className="flex items-center w-full gap-4"
                        onClick={() => {
                          if (notification.onClick) notification.onClick();
                          if (notification.type !== 'important') handleRead(notification.id);
                          setShowDropdownMenu(false);
                        }}
                      >
                        {getIcon()}
                        <div className="flex w-[calc(100%-72px)] flex-wrap text-sm text-gray-700 leading-tight">
                          <span>
                            {notification.message}
                            {notification.cta && (
                              <>
                                {' '}
                                <span className="underline underline-offset-2">{notification.cta}</span>
                              </>
                            )}
                          </span>
                        </div>
                        <div
                          className={classNames('w-2 h-2 flex items-center justify-center rounded-full', {
                            'bg-red-500': !notification.isRead,
                            'bg-gray-300': notification.isRead
                          })}
                        />
                      </Link>
                    </DropdownMenuItem>
                  );
                })
            ) : (
              <li className="flex flex-col h-60 w-full font-normal items-center justify-center px-4 py-2 text-lg text-center">
                <FontAwesomeIcon icon={faListCheck} size="3x" className="mb-4 text-blue/50" />
                <span className="text-[#212121] font-medium">Centro de notificaciones</span>
                <span className="text-sm text-pretty text-gray-400 w-[70%]">
                  Aquí encontrarás las notificaciones sobre tu cuenta y tu negocio.
                </span>
              </li>
            )}
          </ul>
        </DropdownMenuContent>
      </DropdownMenu>
      {showConfirmation && (
        <ConfirmationModal
          {...confirmationItems}
          setShowConfirmation={setShowConfirmation}
          onConfirm={() => mutation.mutate()}
        />
      )}
    </>
  );
};
