import React, { FunctionComponent } from 'react';
import { email, hangout, whatsapp } from '@/static/images';
import styled from 'styled-components';

interface MessageIconsPropsI {
  hiddenWhatsapp?: boolean;
  hiddenEmail?: boolean;
  hiddenHangout?: boolean;
}

export const MessageIcons: FunctionComponent<MessageIconsPropsI> = ({
  hiddenWhatsapp = false,
  hiddenEmail = false,
  hiddenHangout = false
}) => {
  return (
    <ContentPayments>
      {!hiddenWhatsapp && <img src={whatsapp} alt="" />}
      {!hiddenEmail && <img src={email} alt="" width={25} />}
      {!hiddenHangout && <img src={hangout} alt="" />}
    </ContentPayments>
  );
};

const ContentPayments = styled.div`
  display: flex;
  img {
    margin-left: 3px;
    margin-right: 3px;
  }
`;
