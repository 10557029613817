import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@/components';
import { theme } from '@/components/App';
import { data } from './data';
import { PlanCharacteristics } from '@/components/PlanCharacteristics';
import { PaymentsIcons } from '@/components/PaymentsIcons';
import { MessageIcons } from '@/components/MessageIcons';
import { useAuth } from '@/contexts';

type TablePlansProps = {
  onSubmit: (planId: number) => void;
  topHeaderTable?: number;
};

export const TablePlans: FunctionComponent<TablePlansProps> = ({ onSubmit }) => {
  const headerTableInnerRef = useRef<HTMLTableSectionElement>(null);
  const [scroll, setScroll] = useState<boolean>(false);
  const { session } = useAuth();

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
  }, []);

  const scrollHandler = () => {
    setScroll(() => {
      if (window.scrollY > 950) return true;
      return false;
    });
  };

  const isPlanActive = (planId: number) => {
    if (!session?.active_plan) return false;
    else return session?.active_plan?.agora_plan?.id === planId;
  };

  const renderBody = () => (
    <>
      {data.map((plan) => (
        <TableLine key={plan.id}>
          <TableCell key={`${plan.id}-description`} width={380}>
            <PlanCharacteristics text={plan.text} description={plan.description} />
          </TableCell>
          <TableCell key={`${plan.id}-pro`}>
            <ElementCenter>
              {plan.pro.available ? (
                <>
                  <Icon name="check_plan" width="16px" height="18px" color="#09D5A1" />
                  <p>
                    {plan.pro.commission !== null
                      ? plan.pro.commission === 0
                        ? 'Sin comisiones'
                        : `Comisión: ${plan.pro.commission}%`
                      : null}
                  </p>
                </>
              ) : null}
              {plan.id === 8 ? <PaymentsIcons /> : null}
              {plan.id === 16 ? <MessageIcons /> : null}
            </ElementCenter>
          </TableCell>
          <TableCell key={`${plan.id}-event`}>
            <ElementCenter>
              {plan.event.available ? (
                <>
                  <Icon name="check_plan" width="16px" height="18px" color="#09D5A1" />
                  <p>
                    {plan.event.commission !== null
                      ? plan.event.commission === 0
                        ? 'Sin comisiones'
                        : `Comisión: ${plan.event.commission}%`
                      : null}
                  </p>
                </>
              ) : null}
              {plan.id === 8 ? <PaymentsIcons hiddenCash hiddenTransfer /> : null}
              {plan.id === 16 ? <MessageIcons /> : null}
            </ElementCenter>
          </TableCell>
        </TableLine>
      ))}
    </>
  );

  const renderHeaders = () => (
    <>
      <TableCellHeader>
        <ContentHeader>{scroll && <TextHeader>{''}</TextHeader>}</ContentHeader>
      </TableCellHeader>
      <TableCellHeader>
        <ContentHeader>
          {!scroll ? (
            <TextHeader marginBottom={10}>Profesional</TextHeader>
          ) : (
            <PlanButtonHeader onClick={() => onSubmit(3)} disabled={isPlanActive(3)}>
              {!scroll ? 'Profesional' : isPlanActive(3) ? 'Plan actual' : 'Empezá gratis'}
            </PlanButtonHeader>
          )}
        </ContentHeader>
      </TableCellHeader>
      <TableCellHeader>
        <ContentHeader>
          {!scroll ? (
            <TextHeader marginBottom={10}>Eventos</TextHeader>
          ) : (
            <PlanButtonHeader onClick={() => onSubmit(2)} disabled={isPlanActive(2)}>
              {!scroll ? 'Eventos' : 'Elegir'}
            </PlanButtonHeader>
          )}
        </ContentHeader>
      </TableCellHeader>
    </>
  );

  return (
    <>
      <Title>¿Qué incluyen nuestros planes?</Title>
      <TableContainer>
        <TableHeader ref={headerTableInnerRef} id="header-table">
          <TableLine>{renderHeaders()}</TableLine>
        </TableHeader>
        <tbody>{renderBody()}</tbody>
      </TableContainer>
    </>
  );
};

const Title = styled.h2`
  font-weight: 500;
  font-size: 35px;
  line-height: 42px;
  text-align: center;
  color: #212121;
  margin-top: 60px;
  margin-bottom: 40px;
`;

const TableCell = styled.td`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.font.size.lg};
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 18px;
  padding: 18px 0px;
  text-align: left;
  border: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;

const TableCellHeader = styled.th`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 15px;
  font-style: normal;
  line-height: 19px;
  text-align: left;
  font-weight: normal;
`;

const TableLine = styled.tr`
  :nth-of-type(odd) {
    background-color: rgba(243, 243, 243, 0.5);
  }
`;

const TableContainer = styled.table`
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
  max-width: 1536px;
  margin: auto;
`;

const TableHeader = styled.thead`
  width: 100%;
  height: 80px;
  tr {
    background-color: white !important;
  }
  position: sticky;
  inset-block-start: 0;
  top: 128px;
  z-index: 49;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const TextHeader = styled.p<{ marginBottom?: number }>`
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${theme.colors.black};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0px')};
`;

const PlanButtonHeader = styled.button<{ marginBottom?: number; disabled?: boolean }>`
  background: ${({ disabled }) => (disabled ? `${theme.colors.blue[500]}` : `${theme.colors.white}`)};
  box-shadow: none;
  border: 1px solid ${theme.colors.blue[500]};
  border-radius: 3px;
  color: ${({ disabled }) => (disabled ? `${theme.colors.white}` : `${theme.colors.blue[500]}`)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '0px')};
  width: 191px;
  height: 39px;
  font-size: 15px;
  line-height: 18px;
  width: 191px;
  height: 39px;
  padding-bottom: 3px;
  ${({ disabled }) => (disabled ? `opacity: .5;` : ``)};

  &:hover {
    color: ${theme.colors.white};
    background: ${theme.colors.blue[500]};
  }
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
`;

const ElementCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin-top: 5px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #757575;
  }
`;
