import { Button, Column, Row, Select } from '@/components';
import { scrollBarTW } from '@/components/App';
import {
  faListCheck,
  faMinus,
  faPerson,
  faPlus,
  faSearch,
  faXmarkCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { useUniqueEvent } from '../../context';
import { Input, InputAutocomplete } from '@/components/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LabelValue } from '@/types';

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};

const schema = yup.object().shape({
  first_name: yup.string().required('Campo requerido'),
  last_name: yup.string().required('Campo requerido'),
  email: yup.string().email('Email no valido').required('Campo requerido')
});

export const Invitations: FunctionComponent = () => {
  const {
    emailTags,
    inputInvitation,
    setInputInvitation,
    handleCancelInvitation,
    handlePasteInvitation,
    onKeyDownInvitation,
    selectInvitationTicketsOptions,
    selectTicketInvitation,
    handleSubmitInvitation,
    handleQuantityInvitation,
    quantityInvitation,
    getSlotsTicket,
    isInfoMode,
    clients: _clients,
    mutationSendInvitationIsLoading
  } = useUniqueEvent();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [mode, setMode] = React.useState<'custom' | 'multiple'>('custom');

  const [customer, setCustomer] = React.useState<LabelValue | undefined>(undefined);

  const clients =
    _clients?.map((client) => ({
      firstName: client.user?.first_name,
      lastName: client.user?.last_name,
      email: client.user?.email
    })) || [];

  const clientOptions = clients.map((client) => ({
    label: `${client.firstName} ${client.lastName} - ${client.email}`,
    value: client.email || ''
  }));

  const {
    handleSubmit,
    trigger,
    watch,
    formState: { errors },
    control,
    setValue
  } = useForm<FormValues>({ resolver: yupResolver(schema), mode: 'all' });

  // Watch for changes in the form values
  const firstName = watch('first_name');
  const lastName = watch('last_name');
  const email = watch('email');

  const tabs = [
    {
      label: 'Invitacion personalizada',
      icon: faPerson,
      iconColor: '#FF5A5F',
      active: mode === 'custom',
      onClick: () => {
        setMode('custom');
      }
    },
    {
      label: 'Lista de invitados',
      icon: faListCheck,
      iconColor: '#2D8CFF',
      active: mode === 'multiple',
      onClick: () => {
        setMode('multiple');
      }
    }
  ];

  React.useEffect(() => {
    if (customer) {
      const selectedClient = clients?.find((client) => client.email === customer?.value);
      const isFirstNameChanged = firstName !== selectedClient?.firstName;
      const isLastNameChanged = lastName !== selectedClient?.lastName;
      const isEmailChanged = email !== selectedClient?.email;

      if (isFirstNameChanged || isLastNameChanged || isEmailChanged) {
        // Clear the selected client if the form values have been changed
        setCustomer(undefined);
      }
    }
  }, [firstName, lastName, email]);

  React.useEffect(() => {
    if (customer) {
      const selectedClient = clients?.find((client) => client.email === customer?.value);
      setValue('first_name', selectedClient?.firstName || '');
      setValue('last_name', selectedClient?.lastName || '');
      setValue('email', selectedClient?.email || '');

      trigger();
    }
  }, [customer]);

  const onFormSubmit: SubmitHandler<FormValues> = (data) => {
    const req = {
      ...data
    };

    handleSubmitInvitation(req);
  };

  const handleFullSubmit = async () => {
    if (mode === 'custom') {
      await handleSubmit(onFormSubmit)();
    } else {
      handleSubmitInvitation();
    }
  };

  return (
    <Column className="w-full" gap={15}>
      <label className="text-lg font-medium mb-2 mt-4">¿A quien invitar?</label>
      <Column gap={20}>
        {!isInfoMode && (
          <div className="md:w-1/2 w-full bg-[#F4F4F4] py-2 px-4">
            <Row className="w-full">
              {tabs.map((tab) => (
                <Row
                  justify="center"
                  align="center"
                  gap={8}
                  key={tab.label}
                  onClick={isInfoMode ? () => null : tab.onClick}
                  className={`w-1/2 text-base sm:text-lg font-medium transition-colors py-2 px-4 rounded cursor-pointer ${
                    tab.active ? 'bg-white shadow-md' : ''
                  }`}
                >
                  <FontAwesomeIcon
                    icon={tab.icon}
                    color={tab.active ? tab.iconColor : '#BDBDBD'}
                    bounce={tab.active}
                    style={{
                      animationIterationCount: '1'
                    }}
                    className="transition-colors"
                  />
                  <span className={`${tab.active ? 'text-[#212121]' : 'text-[#BDBDBD]'}`}>{tab.label}</span>
                </Row>
              ))}
            </Row>
          </div>
        )}
        {mode === 'custom' ? (
          <Column className="w-full md:w-1/2">
            <label className="text-lg font-medium mb-4">Envía una invitación personalizada sin cargo.</label>
            {clients.length === 0 && (
              <>
                <div className="divider text-xs sm:text-sm text-[#868686] pb-2">
                  <FontAwesomeIcon icon={faSearch} className="text-xs sm:text-sm" />
                  Seleccioná un cliente si ya pertenece a tu base de clientes
                </div>
                <div className="mb-4">
                  <InputAutocomplete
                    // @ts-ignore
                    value={customer?.value}
                    onChange={(selectedItem) => {
                      if (selectedItem) {
                        setCustomer(selectedItem);
                      } else {
                        setCustomer(undefined);
                      }
                    }}
                    options={clientOptions}
                    placeholder="Cliente"
                  />
                </div>
                <div className="divider text-xs sm:text-sm text-[#868686] pb-2">
                  ó ingresá manualmente los datos del cliente
                </div>
              </>
            )}

            <Row gap={12} className="w-full mb-4">
              <div className="w-1/2">
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      inputId="first_name"
                      placeholder="Nombre"
                      error={errors?.first_name?.message}
                    />
                  )}
                />
              </div>
              <div className="w-1/2">
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      inputId="last_name"
                      placeholder="Apellido"
                      error={errors?.last_name?.message}
                    />
                  )}
                />
              </div>
            </Row>
            <div className="mb-4">
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <Input
                    inputId="email"
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const formattedValue = e.target.value.trim().toLowerCase();
                      onChange(formattedValue);
                    }}
                    placeholder="Email"
                    error={errors?.email?.message as string}
                    {...field}
                  />
                )}
              />
            </div>
          </Column>
        ) : (
          <Column>
            <label className="text-lg font-medium mb-4">
              Envía invitaciones sin cargo a una lista de emails.
            </label>
            <Row
              align="flex-start"
              onClick={() => inputRef.current?.focus()}
              className={`cursor-text flex-wrap w-full md:w-1/2 h-40 rounded bg-[#fff] overflow-y-auto ${scrollBarTW} p-3 border border-[rgba(0, 0, 0, 0.35)] focus-within:border-[#0072FB]`}
            >
              {emailTags.map((tag, index) => (
                <Row
                  align="center"
                  gap={4}
                  key={index}
                  className={`rounded-full px-2 py-1 mr-1 mt-1 text-[#fff] whitespace-nowrap`}
                  style={{ backgroundColor: tag.isValid ? '#7ba9ff' : 'rgba(255, 70, 88, 0.7)' }}
                >
                  <span className="text-sm">{tag.value}</span>
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    color="rgba(0, 0, 0, 0.35)"
                    onClick={() => handleCancelInvitation(tag)}
                    className="cursor-pointer"
                  />
                </Row>
              ))}
              <input
                ref={inputRef}
                value={inputInvitation}
                className="w-full border-none py-2 placeholder:text-xs md:placeholder:text-sm"
                onKeyDown={onKeyDownInvitation}
                onChange={(e) => setInputInvitation(e.target.value)}
                onPaste={handlePasteInvitation}
                placeholder="Ingresá la lista de emails separados por “,” o “;”"
              />
            </Row>
          </Column>
        )}
      </Column>

      <Column>
        <label className="text-lg font-medium ">Tipo y cantidad de entradas</label>
        <label className="font-normal text-sm mb-4 text-[#858585]">
          Seleccioná tipo y cantidad de entradas por invitado
        </label>
        <Row align="center" gap={10} className="w-full md:w-3/5">
          <div className="w-3/5">
            <Select
              options={selectInvitationTicketsOptions}
              value={selectTicketInvitation?.label}
              placeholder="Tipo de entrada"
              showBackground
            />
          </div>
          <Row align="center" gap={11} className="justify-center md:justify-start">
            <div
              className={`px-2 py-1 rounded bg-[#FFF] shadow-[0px_3px_4px_0px_#0000001F] cursor-pointer ${
                quantityInvitation === 0 && 'invisible'
              }`}
              onClick={() => handleQuantityInvitation(quantityInvitation - 1)}
            >
              <FontAwesomeIcon icon={faMinus} size="1x" color="#868686" />
            </div>
            <span className="flex justify-center text-[#868686] w-6">x{quantityInvitation}</span>
            <div
              className="px-2 py-1 rounded bg-[#FFF] shadow-[0px_3px_4px_0px_#0000001F] cursor-pointer"
              onClick={() => handleQuantityInvitation(quantityInvitation + 1)}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" color="#868686" />
            </div>
          </Row>
        </Row>
        <label
          className={`font-normal ml-1 text-xs text-[#858585] ${
            selectTicketInvitation ? 'visible' : 'invisible'
          }`}
        >
          Entradas disponibles: {getSlotsTicket()}
        </label>
      </Column>
      <Row className="w-full md:w-80 mt-3">
        <Button
          loading={mutationSendInvitationIsLoading}
          disabled={mutationSendInvitationIsLoading}
          onClick={handleFullSubmit}
          fullWidth
        >
          Enviar invitaciones
        </Button>
      </Row>
    </Column>
  );
};
