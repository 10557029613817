import { Button, Column, ResponsiveContainer, Row } from '@/components';
import React, { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { InputRichText, InputUnit } from '@/components/Input';
import { useMutation } from 'react-query';
import { EmptyObject, ErrorI, PostOfflinePaymentsI } from '@/types/cyclone/requests';
import { useClient } from '@/hooks';
import { useAuth } from '@/contexts';
import { OfflinePaymentTypeE } from '@/types/cyclone/models';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { trackGenericEvent } from '@/analytics';

export const Cash: React.FC = () => {
  useMixpanelTrackPage('Payment Gateways Cash');
  const { client } = useClient();
  const navigate = useNavigate();
  const { session, reloadSession } = useAuth();

  const isCashEnabled = session?.vendor?.is_payment_cash_enabled;
  const offlinePayment = session?.vendor?.offline_payments?.filter(
    (pmt) => pmt.type === OfflinePaymentTypeE.CASH
  )[0];

  const [description, setDescription] = React.useState(offlinePayment?.description);
  const [discount, setDiscount] = React.useState(
    offlinePayment?.discount ? (offlinePayment?.discount * 100).toString() : '0'
  );

  const mutationPost = useMutation<EmptyObject, ErrorI, PostOfflinePaymentsI>(
    (data) =>
      client<EmptyObject>(`wallet/offline_payments`, 'POST', {
        isAuthRequired: true,
        data
      }),
    {
      onSuccess: () => {
        toast.success('Medios de pago actualizados');
        navigate('/ajustes/medios-de-pago');
        reloadSession();
      }
    }
  );

  const onSubmit = () => {
    const floatDiscount = parseFloat(discount) / 100;
    if (+discount < 0 || +discount > 30) {
      return toast.error('El descuento debe ser un número positivo y menor a 31');
    }
    if (!description) {
      return toast.error('Debes ingresar tus instrucciones de pago para poder Guardar');
    }
    const prevOfflinePayments = session?.vendor?.offline_payments;

    const request: PostOfflinePaymentsI = {
      offline_payments: [
        ...(prevOfflinePayments?.filter((pmt) => pmt.type !== OfflinePaymentTypeE.CASH) || []),
        {
          description: description,
          discount: floatDiscount,
          type: OfflinePaymentTypeE.CASH
        }
      ]
    };
    mutationPost.mutate(request);
  };

  const handleDisabled = () => {
    const updateOfflinePayments = session?.vendor?.offline_payments?.filter(
      (pmt) => pmt.type !== OfflinePaymentTypeE.CASH
    );
    mutationPost.mutate({ offline_payments: updateOfflinePayments });
  };

  return (
    <ResponsiveContainer>
      <section className="my-2">
        <Row align="center" justify="space-between" className="mb-4 pb-4">
          <Column>
            <Row align="baseline" gap={10}>
              <Link to={'/ajustes/medios-de-pago'} className="text-xl sm:text-3xl">
                Medios de pago
              </Link>
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
              <p className="text-xl sm:text-3xl">Efectivo</p>
            </Row>
            <p className="text-sm text-[#828282]">
              Gestioná los medios de pago aceptados en tu negocio.{' '}
              <Link
                to="https://ayuda.agora.red/es/articles/7920323-como-te-pagan-tus-clientes"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackGenericEvent('Button Help Center Clicked')}
                className="cursor-pointer text-blue"
              >
                Más info.
              </Link>
            </p>
          </Column>
        </Row>
        <Column gap={16} className="w-full sm:w-[90%]">
          <div className="flex sm:flex-row flex-col gap-4 w-full">
            <article className="w-full sm:w-[70%] flex gap-4 items-center sm:shadow-[0px_1px_4px_0px_#00000040]">
              <Column className="w-full justify-start">
                <h2 className="font-medium sm:border-b sm:p-4">
                  Instrucciones para habilitar cobros en efectivo
                </h2>
                <ol className="text-sm mx-4 sm:p-4 space-y-2 list-decimal list-outside min-h-[calc(240px-57px)]">
                  <li>
                    Explica a tus clientes cómo pagarte completando las <b>instrucciones de pago</b> a
                    continuación
                  </li>
                  <li>
                    Opcionalmente, podés ofrecer un <b>descuento</b> para cobros en efectivo
                  </li>
                  <li>Haz click en el botón “Habilitar”</li>
                  <li>
                    Tus clientes podrán ingresar reservas sin pagar por anticipado. Podrás confirmar la
                    recepción del pago desde “Actividad”
                  </li>
                </ol>
              </Column>
            </article>
            <div className="hidden sm:flex flex-col gap-4 sm:w-[30%]">
              <article className="w-full h-1/2 flex p-4 flex-col gap-4 shadow-[0px_1px_4px_0px_#00000040]">
                <h2 className="font-medium">Descuento para pagos en efectivo</h2>

                <InputUnit
                  pattern="^\d*\.?\d*$"
                  inputId="discount"
                  unit="%"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setDiscount(e.target.value);
                  }}
                  value={discount}
                />
              </article>
              <article className="w-full h-1/2 flex flex-col p-4 gap-4 shadow-[0px_1px_4px_0px_#00000040]">
                <h2 className="font-medium">Comisiones</h2>
                <ul className="text-sm mx-4 space-y-2 list-disc list-outside">
                  <li>
                    Los cobros en efectivo <b>no tienen comisión</b>
                  </li>
                </ul>
              </article>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col gap-4 w-full">
            <article className="w-full sm:w-[70%] flex gap-4 items-center sm:shadow-[0px_1px_4px_0px_#00000040] border-y-2 sm:border-y-0 py-6 sm:py-0">
              <Column gap={16} className="w-full sm:p-4">
                <h2 className="font-medium">Instrucciones de pago - Efectivo</h2>
                <p className="text-sm">
                  Tus clientes verán estas instrucciones a la hora de ingresar una reserva
                </p>
                <InputRichText
                  inputId="instructions"
                  value={description || ''}
                  onChange={setDescription}
                  rows={300}
                />
              </Column>
            </article>
            <div className="sm:hidden flex flex-col w-full">
              <article className="w-full h-1/2 flex flex-col gap-4 border-b-2 sm:border-b-0 pb-6">
                <h2 className="font-medium">Descuento para pagos en efectivo</h2>
                <div className="w-1/2">
                  <InputUnit
                    pattern="^\d*\.?\d*$"
                    inputId="discount"
                    unit="%"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setDiscount(e.target.value);
                    }}
                    value={discount}
                  />
                </div>
              </article>
              <article className="w-full h-1/2 flex flex-col gap-4 pt-6">
                <h2 className="font-medium">Comisiones</h2>
                <ul className="text-sm mx-4 space-y-2 list-disc list-outside">
                  <li>
                    Los cobros en efectivo <b>no tienen comisión</b>
                  </li>
                </ul>
              </article>
            </div>
            <div className="z-50 sticky sm:hidden bottom-10 left-0 right-0">
              <article className="h-fit w-full sm:w-[30%] gap-4 flex items-center bg-[#fbfbfb]/90 shadow-[0px_0px_10px_5px_#fbfbfb] sm:shadow-[0px_1px_4px_0px_#00000040] p-4">
                <Button loading={mutationPost.isLoading} onClick={onSubmit} fullWidth rounded>
                  {isCashEnabled ? 'Guardar' : 'Habilitar'}
                </Button>
                {isCashEnabled && (
                  <Button onClick={handleDisabled} fullWidth rounded color="red">
                    Deshabilitar
                  </Button>
                )}
              </article>
            </div>
            <article className="hidden h-fit w-full sm:w-[30%] gap-4 sm:flex items-center bg-[#fbfbfb]/90 shadow-[0px_0px_10px_5px_#fbfbfb] sm:shadow-[0px_1px_4px_0px_#00000040] p-4">
              <Button loading={mutationPost.isLoading} fullWidth onClick={onSubmit} rounded>
                {isCashEnabled ? 'Guardar' : 'Habilitar'}
              </Button>
              {isCashEnabled && (
                <Button onClick={handleDisabled} variant="link" fullWidth rounded color="red">
                  Deshabilitar
                </Button>
              )}
            </article>
          </div>
        </Column>
      </section>
    </ResponsiveContainer>
  );
};
