import { REGEX_NO_SPECIAL_CHARACTERS } from '@/constants/regex';
import * as yup from 'yup';

export const getSchema = (usernameIsAvailable: boolean) =>
  yup.object().shape({
    storefront_name: yup.string().required('Nombre es un campo requerido'),
    profession: yup
      .string()
      .required('Profesión es un campo requerido')
      .max(150, 'Debe contener maximo 150 caracteres'),
    bio: yup.string(),
    username: yup
      .string()
      .required('Nombre de usuario es un campo requerido')
      .max(20, 'Debe contener maximo 20 caracteres')
      .min(5, 'Debe contener minimo 5 caracteres')
      .matches(REGEX_NO_SPECIAL_CHARACTERS, 'No debe contener caracteres especiales')
      .test('username-valid', 'Necesita ser una username valido', () => usernameIsAvailable),
    phone: yup
      .number()
      .required('Teléfono de contacto es un campo requerido')
      .typeError('Teléfono de contacto es un campo requerido')
  });

export const MIN_USERNAME_LENGTH = 4;
