import { Button, Column, ResponsiveContainer, Row } from '@/components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useAuth, useConfirmation } from '@/contexts';
import { SupportedCountriesE, VendorI, WalletTypeE } from '@/types/cyclone/models';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { EmptyObject } from 'react-hook-form';
import { ErrorI, GetConnectI } from '@/types/cyclone/requests';
import { useClient } from '@/hooks';
import { toast } from 'react-toastify';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { trackGenericEvent } from '@/analytics';

export const MercadoPago: React.FC = () => {
  useMixpanelTrackPage('Payment Gateways Mercado Pago');
  const { session, reloadSession } = useAuth();
  const navigate = useNavigate();
  const { confirm } = useConfirmation();
  const { client } = useClient();

  const paymentGateways = session?.vendor?.payment_gateways || [];
  const isWalletAssociated = paymentGateways.includes(WalletTypeE.MERCADO_PAGO);

  const country = session ? session.vendor!.country : SupportedCountriesE.ARGENTINA;

  const buildLinks = () => {
    switch (country) {
      case SupportedCountriesE.URUGUAY:
        return {
          methods: 'https://www.mercadopago.com.uy/ayuda/Medios-de-pago-para-tus-compradores-uy_221',
          comissions: 'https://www.mercadopago.com.uy/ayuda/33399',
          period: 'https://www.mercadopago.com.uy/costs-section/merchant-svcs'
        };
      default:
        return {
          methods: 'https://www.mercadopago.com.ar/ayuda/medios-de-pago-vendedores_221',
          comissions: 'https://www.mercadopago.com.ar/ayuda/33399',
          period: 'https://www.mercadopago.com.ar/costs-section/merchant-svcs'
        };
    }
  };

  const { data: connectMP } = useQuery(
    ['mercado_pago', 'connect'],
    async () =>
      await client<GetConnectI>('wallet/connect', 'POST', {
        isAuthRequired: true,
        data: {
          wallet_type: WalletTypeE.MERCADO_PAGO,
          email: session?.email
        }
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: session ? !isWalletAssociated : false
    }
  );

  const mutationResetWallet = useMutation<EmptyObject, ErrorI, VendorI>(
    (vendor) =>
      client<EmptyObject>(`vendors/${vendor.id}/reset_wallet`, 'POST', {
        isAuthRequired: true
      }),
    {
      onSuccess: () => {
        toast.success('Medios de pago actualizados');
        reloadSession();
        setTimeout(() => window.location.reload(), 1000);
        navigate('/ajustes/medios-de-pago');
      }
    }
  );

  const handleMPDisconnect = async () => {
    confirm({
      status: 'danger',
      content:
        '¿Estás seguro que deseas desconectar Mercado Pago? Tus clientes no podrán pagar con esta opción.',
      title: 'Desconectar Mercado Pago',
      confirmText: 'Si, desconectar',
      onConfirm: () => mutationResetWallet.mutate(session!.vendor!)
    });
  };

  const handleOnClick = () => {
    if (isWalletAssociated) {
      handleMPDisconnect();
    } else {
      window.location.href = connectMP?.url || '';
    }
  };

  return (
    <ResponsiveContainer>
      <section className="my-2">
        <Row align="center" justify="space-between" className="mb-4 pb-4">
          <Column>
            <Row align="baseline" gap={10}>
              <Link to={'/ajustes/medios-de-pago'} className="text-xl sm:text-3xl">
                Medios de pago
              </Link>
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
              <p className="text-xl sm:text-3xl">Mercadopago</p>
            </Row>
            <p className="text-sm text-[#828282]">
              Gestioná los medios de pago aceptados en tu negocio.{' '}
              <Link
                to="https://ayuda.agora.red/es/articles/7920323-como-te-pagan-tus-clientes"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackGenericEvent('Button Help Center Clicked')}
                className="cursor-pointer text-blue"
              >
                Más info.
              </Link>
            </p>
          </Column>
        </Row>
        <div className="w-full flex flex-col sm:gap-4 sm:w-[90%]">
          <div className="flex flex-col sm:flex-row gap-4 w-full">
            <article className="w-full sm:w-[60%] flex gap-4 items-center sm:shadow-[0px_1px_4px_0px_#00000040]">
              <Column className="w-full">
                <h2 className="font-medium sm:border-b sm:p-4">Instrucciones para conectar tu billetera</h2>
                <ol className="text-sm mx-4 sm:p-4 space-y-2 list-decimal list-outside">
                  <li>Haz click en el botón “Conectar” </li>
                  <li>
                    Mercado Pago solicitará las credenciales para acceder a tu cuenta (e-mail y contraseña)
                  </li>
                  <li>
                    Si estás navegando desde un celular o computadora con una cuenta de Mercado Pago
                    conectada, la conexión se realizará sin solicitar las credenciales
                  </li>
                </ol>
              </Column>
            </article>
            <article className="hidden sm:flex w-full sm:w-[40%] gap-4 items-center sm:shadow-[0px_1px_4px_0px_#00000040]">
              <Column className="w-full">
                <h2 className="font-medium sm:border-b p-4">Comisiones de Mercado Pago</h2>
                <ul className="text-sm p-4 mx-4 space-y-2 list-disc list-outside min-h-[128px]">
                  <li>
                    <b>
                      Desde 1,8% por transacción{' '}
                      <Link
                        to={buildLinks().comissions}
                        target="_blank"
                        className="text-blue underline underline-offset-1"
                      >
                        (más info)
                      </Link>
                    </b>
                  </li>
                  <li>
                    Podrás configurar el plazo de acreditación para tus cobros desde tu billetera de Mercado
                    Pago o ingresando a{' '}
                    <Link
                      to={buildLinks().period}
                      target="_blank"
                      className="text-blue underline underline-offset-1"
                    >
                      este enlace
                    </Link>
                  </li>
                </ul>
              </Column>
            </article>
          </div>
          <div className="flex flex-col sm:flex-row sm:gap-4 w-full">
            <article className="w-full sm:w-[60%] flex gap-4 items-center sm:shadow-[0px_1px_4px_0px_#00000040] py-6 sm:py-0 sm:border-y-0 sm:my-0 border-y-2 my-6">
              <Column className="w-full">
                <h2 className="font-medium sm:border-b sm:p-4">
                  Métodos de pago disponibles con Mercado Pago {country}
                </h2>
                <ul className="text-sm mx-4 space-y-2 list-disc list-outside sm:p-4">
                  <li>
                    Conectando tu billetera de Mercado Pago podrás aceptar pagos de tus clientes mediante
                    tarjetas de crédito, tarjetas de débito, dinero en cuenta de Mercado Pago y Mercado
                    Crédito
                  </li>
                  <li>
                    Puedes consultar todos los medios de pago en{' '}
                    <Link
                      to={buildLinks().methods}
                      target="_blank"
                      className="text-blue underline underline-offset-1"
                    >
                      este enlace
                    </Link>
                  </li>
                </ul>
              </Column>
            </article>
            <article className="sm:hidden flex w-full sm:w-[40%] items-center sm:shadow-[0px_1px_4px_0px_#00000040] border-b-2 pb-6 mb-6">
              <Column className="w-full">
                <h2 className="font-medium sm:border-b sm:p-4">Comisiones de Mercado Pago</h2>
                <ul className="text-sm mx-4 sm:p-4 space-y-2 list-disc list-outside sm:min-h-[128px]">
                  <li>
                    <b>
                      Desde 1,8% por transacción{' '}
                      <Link
                        to={buildLinks().comissions}
                        target="_blank"
                        className="text-blue underline underline-offset-1"
                      >
                        (más info)
                      </Link>
                    </b>
                  </li>
                  <li>
                    Podrás configurar el plazo de acreditación para tus cobros desde tu billetera de Mercado
                    Pago o ingresando a{' '}
                    <Link
                      to={buildLinks().period}
                      target="_blank"
                      className="text-blue underline underline-offset-1"
                    >
                      este enlace
                    </Link>
                  </li>
                </ul>
              </Column>
            </article>
            <div className="z-50 sticky sm:hidden bottom-10 left-0 right-0">
              <article className="h-fit w-full sm:w-[40%] flex items-center bg-[#fbfbfb]/90 shadow-[0px_0px_10px_5px_#fbfbfb] sm:shadow-[0px_1px_4px_0px_#00000040] p-4">
                <Button onClick={handleOnClick} fullWidth rounded color={isWalletAssociated ? 'red' : 'blue'}>
                  {isWalletAssociated ? 'Desconectar' : 'Conectar'}
                </Button>
              </article>
            </div>
            <article className="hidden h-fit w-full sm:w-[40%] sm:flex items-center bg-[#fbfbfb]/90 shadow-[0px_0px_10px_5px_#fbfbfb] sm:shadow-[0px_1px_4px_0px_#00000040] p-4">
              <Button onClick={handleOnClick} fullWidth rounded color={isWalletAssociated ? 'red' : 'blue'}>
                {isWalletAssociated ? 'Desconectar' : 'Conectar'}
              </Button>
            </article>
          </div>
        </div>
      </section>
    </ResponsiveContainer>
  );
};
