import React, { FunctionComponent, useState } from 'react';
import { Controller, Control, DeepMap, FieldError, FieldValues } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { InputFile, Input, InputRichText, ImageCropper } from '@/components';
import { usePreviousImage } from '@/hooks';
import { addCover } from '@/static/images';
import { useAuth } from '@/contexts';
import { SupportedCountriesE, WalletTypeE } from '@/types/cyclone/models';
import { ImageActionButton } from './ImageActionButton';
import { getCurrencySymbolFromCountry } from '@/utils/money';

type AboutProps = {
  control: Control<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  coverImage: File | undefined;
  handleCoverImage: (image?: File) => void;
  aboutErrors: Record<string, boolean>;
  defaultImage: string | undefined;
  description: string;
  handleDescription: (description: string) => void;
  isEdit: boolean;
};

export const About: FunctionComponent<AboutProps> = ({
  control,
  errors,
  coverImage,
  handleCoverImage,
  aboutErrors,
  defaultImage,
  description,
  handleDescription,
  isEdit
}) => {
  const prevCoverImage = usePreviousImage(coverImage);
  const [showModal, setShowModal] = useState(false);
  const { session } = useAuth();

  const country = session ? session.vendor!.country : SupportedCountriesE.ARGENTINA;
  const currency = getCurrencySymbolFromCountry(country);

  const stripeEnabled = session?.vendor?.payment_gateways?.some((gateway) => gateway === WalletTypeE.STRIPE);

  return (
    <Container>
      {showModal && (
        <ImageCropper
          image={coverImage}
          prevImage={prevCoverImage || defaultImage}
          onClose={() => setShowModal(false)}
          setImage={handleCoverImage}
          aspectRatio={1 / 1}
        />
      )}
      <FlexWidth>
        <Title>Acerca de tu plan mensual</Title>
      </FlexWidth>
      <FirstRow>
        <Controller
          control={control}
          name="name"
          render={({ field: { onChange, value } }) => (
            <Input
              inputId="subscription-name"
              placeholder="Nombre del plan mensual"
              helpText="Este será el nombre visible de tu Plan mensual"
              error={errors.name?.message}
              value={value || ''}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="price"
          render={({ field: { onChange, value } }) => (
            <Input
              pattern="\d*"
              inputId="subscription-price"
              placeholder={country}
              error={errors.price?.message}
              postFix={currency}
              value={value || ''}
              onChange={onChange}
            />
          )}
        />
        {stripeEnabled && (
          <Controller
            control={control}
            name="price_usd"
            render={({ field: { onChange, value } }) => (
              <Input
                pattern="\d*"
                inputId="subscription-price-usd"
                placeholder="Precio (Otros países)"
                error={errors.price?.message}
                postFix="US$"
                value={value || ''}
                onChange={onChange}
              />
            )}
          />
        )}

        {/* <Controller
          control={control}
          name="availability"
          render={({ field: { onChange, value } }) => (
            <Input
              pattern="\d*"
              inputId="subscription-availability"
              placeholder="Planes mensuales disponibles"
              helpText="Indicá la cantidad máxima de planes mensuales disponibles"
              error={errors.availability?.message}
              value={value || ''}
              onChange={onChange}
            />
          )}
        /> */}
      </FirstRow>
      <SecondRow>
        <InputRichText
          inputId="subscription-description"
          placeholder="Descripción"
          helpText="Contale a tus clientes de qué trata tu Plan mensual"
          onChange={handleDescription}
          showCount
          maxLength={500}
          value={description || ''}
          error={aboutErrors.description ? 'Campo requerido' : undefined}
          isEdit={isEdit}
        />
        <InputFile
          reference="subscription-cover-page"
          handleFile={handleCoverImage}
          styles={getCoverImageStyles(aboutErrors.coverImage)}
          error={aboutErrors.coverImage ? 'Campo requerido' : undefined}
        >
          {prevCoverImage || defaultImage ? (
            <CoverImageContent>
              <CoverImage image={prevCoverImage || defaultImage} />
              <ImageActionButton
                handleCrop={() => {
                  setShowModal(true);
                }}
                //handleDelete={() => handleCoverImage(undefined)}
              />
            </CoverImageContent>
          ) : (
            <Flex>
              <AddCoverImage src={addCover} />
              <p>Agregar imagen de portada</p>
            </Flex>
          )}
        </InputFile>
      </SecondRow>
    </Container>
  );
};

const FlexWidth = styled.div`
  display: flex;
`;

const CoverImageContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;
`;

const AddCoverImage = styled.img`
  margin-bottom: 11px;
  width: 71px;
  height: 64px;
`;

const Container = styled.div`
  margin-bottom: 4rem;
  padding-bottom: 4rem;
  padding-top: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-top: 1px solid ${({ theme }) => theme.colors.grey[500]};
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
  > div {
    &:nth-child(1) {
      width: 50%;
      margin-right: 1rem;
    }
    &:nth-child(2) {
      width: 25%;
      margin-right: 1rem;
    }
    &:nth-child(3) {
      width: 25%;
    }
  }
  ${({ theme }) => theme.breakpoint('md')} {
    margin-bottom: 1rem;
    flex-direction: column;
    > div {
      &:nth-child(1) {
        margin-bottom: 1rem;
        width: 100%;
        margin-right: 0;
      }
      &:nth-child(2) {
        margin-bottom: 1rem;
        width: 100%;
        margin-right: 0;
      }
      &:nth-child(3) {
        width: 100%;
      }
    }
  }
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    &:nth-child(1) {
      width: 50%;
      margin-right: 1rem;
    }
    &:nth-child(2) {
      width: 50%;
    }
  }
  ${({ theme }) => theme.breakpoint('md')} {
    flex-direction: column;
    > div {
      &:nth-child(1) {
        width: 100%;
        margin-bottom: 1rem;
        margin-right: 0;
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
`;

const getCoverImageStyles = (error: boolean) => css`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => (error ? theme.colors.red[200] : theme.colors.white)};
  box-sizing: border-box;
  box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  > svg {
    margin-bottom: 1rem;
    opacity: 0.6;
  }
  > div > svg {
    opacity: 0.6;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.blue[500]};
  }
`;

const CoverImage = styled.div<{ image?: string }>`
  width: 100%;
  height: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  &:first-of-type {
    border-radius: 8px;
  }
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxl};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 37px;
`;
