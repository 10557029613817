import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@/components';
import { theme } from '@/components/App';
import { data } from '../TablePlans/data';
import { PlanCharacteristics } from '@/components/PlanCharacteristics';
import { PaymentsIcons } from '@/components/PaymentsIcons';
import { MessageIcons } from '@/components/MessageIcons';

enum TypePlanEnum {
  pro = 'pro',
  event = 'event',
  personal = 'personal'
}

enum DirectionEnum {
  left = 'left',
  right = 'right'
}

export const TablePlansMobile = () => {
  const [planSelected, setPlanSelected] = useState<TypePlanEnum>(TypePlanEnum.pro);

  const nextPlan = () => {
    if (planSelected == TypePlanEnum.pro) setPlanSelected(TypePlanEnum.event);
    if (planSelected == TypePlanEnum.event) setPlanSelected(TypePlanEnum.personal);
  };

  const previewPlan = () => {
    if (planSelected == TypePlanEnum.event) setPlanSelected(TypePlanEnum.pro);
    if (planSelected == TypePlanEnum.personal) setPlanSelected(TypePlanEnum.event);
  };

  const renderBody = () => (
    <>
      {data.map((plan) => (
        <>
          <TableLine key={plan.id}>
            <TableCell key={`${plan.id}-description`}>
              <PlanCharacteristics text={plan.text} description={plan.description} />
            </TableCell>
            <TableCell key={`${plan.id}`}>
              <ElementCenter>
                {plan[planSelected].available ? (
                  <>
                    <Icon name="check_plan" width="16px" height="18px" color="#09D5A1" />
                    <p>
                      {plan[planSelected].commission !== null
                        ? plan[planSelected].commission === 0
                          ? 'Sin comisiones'
                          : `Comisión: ${plan[planSelected].commission}%`
                        : null}
                    </p>
                  </>
                ) : null}
                {plan.id === 8 ? (
                  <PaymentsIcons
                    hiddenCash={planSelected === TypePlanEnum.event || planSelected === TypePlanEnum.personal}
                    hiddenTransfer={
                      planSelected === TypePlanEnum.event || planSelected === TypePlanEnum.personal
                    }
                  />
                ) : null}
                {plan.id === 16 ? (
                  <MessageIcons
                    hiddenHangout={planSelected === TypePlanEnum.personal}
                    hiddenWhatsapp={planSelected === TypePlanEnum.personal}
                  />
                ) : null}
              </ElementCenter>
            </TableCell>
          </TableLine>
        </>
      ))}
    </>
  );

  const renderHeaders = () => (
    <ContentHeader>
      {planSelected !== TypePlanEnum.pro && (
        <ButtonArrow direction={DirectionEnum.left} onClick={previewPlan}>
          <CustomIcon
            name="chevron_down"
            direction={DirectionEnum.left}
            color={theme.colors.white as string}
          />
        </ButtonArrow>
      )}

      <TextHeader>
        {planSelected === TypePlanEnum.pro ? 'Pro' : planSelected === TypePlanEnum.event ? 'Eventos' : ''}
      </TextHeader>
      {planSelected !== TypePlanEnum.event && (
        <ButtonArrow direction={DirectionEnum.right} onClick={nextPlan}>
          <CustomIcon
            name="chevron_down"
            direction={DirectionEnum.right}
            color={theme.colors.white as string}
          />
        </ButtonArrow>
      )}
    </ContentHeader>
  );

  return (
    <Content>
      <Title>¿Qué incluyen nuestros planes?</Title>
      {renderHeaders()}
      <TableContainer>
        <tbody>{renderBody()}</tbody>
      </TableContainer>
    </Content>
  );
};

const Content = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #212121;
  margin-bottom: 40px;
`;

const TableCell = styled.td`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.font.size.lg};
  font-style: normal;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 18px;
  padding: 18px 0px;
  text-align: left;
  border: 2px solid ${({ theme }) => theme.colors.lightGrey};
`;

const TableLine = styled.tr`
  :nth-of-type(odd) {
    background-color: rgba(243, 243, 243, 0.5);
  }
`;

const TableContainer = styled.table`
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
  max-width: 1536px;
  margin: auto;
`;

const TextHeader = styled.p`
  color: ${theme.colors.black};
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  padding-top: 5px;
`;

const ContentHeader = styled.div`
  position: relative;
  height: 60px;
`;

const ElementCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 120px;
  p {
    margin-top: 5px;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #757575;
  }
`;

const CustomIcon = styled(Icon)<{ direction: DirectionEnum }>`
  transition: transform 0.2s ease;
  transform: ${({ direction }) => (direction == DirectionEnum.right ? 'rotate(-90deg)' : 'rotate(90deg)')};
  width: 17px;
  height: 17px;
  background: ${theme.colors.blue[500]};
  ${({ direction }) =>
    direction == DirectionEnum.right ? 'padding: 0px 3px 0px 0px;' : 'padding: 1px 0px 0px 3px;'};
`;

const ButtonArrow = styled.button<{ direction: DirectionEnum }>`
  width: 38px;
  height: 38px;
  background: ${theme.colors.blue[500]};
  position: absolute;
  ${({ direction }) => `${direction}: 0px`};
  top: 0px;
  border-radius: 50%;
  padding-left: 0.8rem;
`;
