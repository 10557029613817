import { FunctionComponent, useEffect } from 'react';
import { useAuth } from '@/contexts';
import { welcomeOnboarding, welcomeMockupMobile, welcomeMockup } from '@/static/images';
import { Button, Column, Logo, Row } from '@/components';
import { useNavigate } from 'react-router-dom';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { identifyUser, trackGenericEvent } from '@/analytics';
import { useWindowResize } from '@/hooks';
import { theme } from '@/components/App';
import LogRocket from 'logrocket';
import { updateIntercomUser } from '@/services/intercom';

export const Welcome: FunctionComponent = () => {
  useMixpanelTrackPage('Welcome');
  const { session } = useAuth();
  const navigate = useNavigate();
  const { isMobileSize } = useWindowResize();

  useEffect(() => {
    if (session) {
      identifyUser(session);
      updateIntercomUser(session, isMobileSize);
    }
  }, [session]);

  const onClickStartButton = () => {
    trackGenericEvent('Button Selfonboard Start Now Clicked');
    navigate('/onboarding/wizard');
  };

  useEffect(() => {
    if (session) {
      LogRocket.init('s02ka5/agora-flash');
      LogRocket.track('Start Self Onboarding');
      LogRocket.identify(session.id.toString(), {
        email: session.email,
        first_name: session.first_name || '',
        last_name: session.last_name || ''
      });
    }
  }, [session]);

  return (
    <div
      className="w-full h-screen overflow-hidden flex sm:flex-row flex-col justify-center sm:justify-start"
      style={{
        background: isMobileSize
          ? 'linear-gradient(to top, #0072FB40 0%, rgba(217, 217, 217, 0) 100%)'
          : `url(${welcomeOnboarding})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <Column className="sm:p-12 sm:h-full h-1/2 gap-6 items-center sm:items-start justify-center max-w-[425px] w-[80%] sm:w-full mx-auto sm:m-0">
        <Row align="center" justify="center" className="mb-4 gap-4">
          <Logo type="triangle" width={isMobileSize ? '50px' : '40px'} />
          <Logo type="plain" width={isMobileSize ? '115px' : '130px'} color={theme.colors.black as string} />
        </Row>
        <Column className="max-w-[425px] gap-6">
          <h2 className="text-2xl sm:text-4xl font-medium">
            ¡Hola, {session?.first_name}!<br />
          </h2>
          <p className="text-lg sm:text-xl font-medium leading-tight">
            Vas a comenzar el proceso de creación de tu cuenta.
          </p>
          <p className="text-lg sm:text-xl leading-tight">
            No te preocupes si no podés finalizarlo por completo, vas a poder guardar la información y seguir
            en otro momento.
          </p>
        </Column>
        <Button fullWidth={isMobileSize} onClick={onClickStartButton}>
          Empezar ahora
        </Button>
      </Column>
      <div className="h-1/2 sm:h-full flex items-end justify-center overflow-hidden">
        {isMobileSize ? (
          <img
            className="object-contain w-[75%] aspect-auto"
            src={welcomeMockupMobile}
            alt="Welcome Mockup"
          />
        ) : (
          <img
            className="object-cover w-[360px] aspect-auto self-center"
            src={welcomeMockup}
            alt="Welcome Mockup"
          />
        )}
      </div>
    </div>
  );
};
