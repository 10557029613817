import React, { FunctionComponent } from 'react';
import { cash, mercadopago, transfer } from '@/static/images';
import styled from 'styled-components';

interface PaymentsIconsPropsI {
  hiddenCash?: boolean;
  hiddenMercadoPago?: boolean;
  hiddenTransfer?: boolean;
}

export const PaymentsIcons: FunctionComponent<PaymentsIconsPropsI> = ({
  hiddenCash = false,
  hiddenMercadoPago = false,
  hiddenTransfer = false
}) => {
  return (
    <ContentPayments>
      {!hiddenCash && <img src={cash} alt="" />}
      {!hiddenMercadoPago && <img src={mercadopago} alt="" width={25} />}
      {!hiddenTransfer && <img src={transfer} alt="" />}
    </ContentPayments>
  );
};

const ContentPayments = styled.div`
  display: flex;
  img {
    margin-left: 2px;
    margin-right: 2px;
  }
`;
