import React, { useState } from 'react';
import { NavbarItem } from '..';
import { useAuth } from '@/contexts';
import { useNavigate } from 'react-router-dom';
import { trackGenericEvent } from '@/analytics';
import { AgoraPlanNameE } from '@/types/cyclone/models';
import classNames from 'classnames';
import { Row } from '@/components/Row';
import { Column } from '@/components/Column';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faSignOutAlt,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { Dialog, DialogContentMenuMobile } from '@/components/Dialog';

type MobileMenuProps = {
  showMobileMenu: boolean;
  topItems: NavbarItem[];
  bottomItems: NavbarItem[];
  handleItemClick: () => void;
};

export const MobileMenu: React.FC<MobileMenuProps> = ({
  showMobileMenu,
  topItems,
  bottomItems,
  handleItemClick
}) => {
  const { session } = useAuth();
  const { logOut } = useAuth();
  const navigate = useNavigate();
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);

  const toggleDropdown = () => setShowDropdownMenu(!showDropdownMenu);

  const filteredTopItems =
    session?.vendor?.plan_name === AgoraPlanNameE.EVENT
      ? topItems.filter((item) => item.label !== 'Facturación')
      : topItems;
  const buildBottomItems = () => {
    const filteredBottomItems = bottomItems.filter(
      (item) => item.label !== 'Mi perfil' && item.label !== 'Salir'
    );
    filteredBottomItems.push({
      icon: faCircleQuestion,
      label: 'Centro de ayuda',
      onClick: () => trackGenericEvent('Menu Item Clicked', { name: 'Help Center' }),
      link: 'https://ayuda.agora.red/es/'
    });
    return filteredBottomItems;
  };

  return (
    <Dialog open={showMobileMenu} onOpenChange={handleItemClick}>
      <DialogContentMenuMobile>
        <FontAwesomeIcon
          onClick={handleItemClick}
          icon={faXmark}
          size="2x"
          color="#626262"
          className="absolute top-5 right-6 cursor-pointer"
        />
        <nav>
          <Column gap={30} align="flex-start" justify="center" className="px-4 py-8 mt-8 border-b-2 w-full">
            {filteredTopItems.map(({ label, link, icon, isDropdown, dropdownContent }, index) => (
              <Column
                className="items-center cursor-pointer font-medium text-xl w-full"
                key={index}
                onClick={() => {
                  if (isDropdown) toggleDropdown();
                  else {
                    navigate(link);
                    handleItemClick();
                  }
                }}
              >
                {isDropdown ? (
                  <div className="collapse">
                    <input type="checkbox" className="min-h-1" />
                    <div className="collapse-title text-xl font-medium p-0 min-h-1">
                      <Row align="center" justify="space-between" className="w-full">
                        <Row align="center">
                          {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
                          <span style={{ marginLeft: 26, marginRight: 16 }}>{label}</span>
                          {isDropdown && (
                            <FontAwesomeIcon icon={showDropdownMenu ? faChevronUp : faChevronDown} />
                          )}
                        </Row>
                        {!isDropdown && <FontAwesomeIcon icon={faChevronRight} />}
                      </Row>
                    </div>
                    <div className={classNames('collapse-content', { 'mt-6': showDropdownMenu })}>
                      <Column className={classNames('w-full gap-6 pl-12')}>
                        {dropdownContent?.map(({ label, icon, link }) => (
                          <Column
                            className="font-medium text-xl text-[#868686] w-full"
                            key={label}
                            onClick={() => {
                              navigate(link);
                              handleItemClick();
                            }}
                          >
                            {icon && <FontAwesomeIcon icon={icon} />}
                            {label}
                          </Column>
                        ))}
                      </Column>
                    </div>
                  </div>
                ) : (
                  <Row align="center" justify="space-between" className="w-full">
                    <Row align="center">
                      {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
                      <span style={{ marginLeft: 26, marginRight: 16 }}>{label}</span>
                      {isDropdown && (
                        <FontAwesomeIcon icon={showDropdownMenu ? faChevronUp : faChevronDown} />
                      )}
                    </Row>
                    {!isDropdown && <FontAwesomeIcon icon={faChevronRight} />}
                  </Row>
                )}
              </Column>
            ))}
          </Column>
          <Column gap={30} align="flex-start" justify="center" className="px-4 py-8 border-b-2 w-full">
            {buildBottomItems().map(({ label, link, icon }, index) => (
              <Column
                className="items-center cursor-pointer font-medium text-xl w-full"
                key={index}
                onClick={() => {
                  if (link.includes('http')) {
                    window.open(link, '_blank');
                  } else {
                    navigate(link);
                    handleItemClick();
                  }
                }}
              >
                <Row align="center" justify="space-between" className="w-full">
                  <Row align="center">
                    {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
                    <span style={{ marginLeft: 26, marginRight: 16 }}>{label}</span>
                  </Row>
                  <FontAwesomeIcon icon={faChevronRight} />
                </Row>
              </Column>
            ))}
          </Column>
          <Column align="center" justify="center" className="px-4 py-8">
            <Row
              align="center"
              gap={10}
              className="text-xl"
              onClick={() => {
                logOut();
              }}
            >
              <FontAwesomeIcon icon={faSignOutAlt} fixedWidth />
              <span>Cerrar sesión</span>
            </Row>
          </Column>
        </nav>
      </DialogContentMenuMobile>
    </Dialog>
  );
};
