import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Checkbox,
  Icon,
  Input,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tooltip,
  InputRichText
} from '@/components';
import { PostCrowdfundingPlanI } from '@/types/cyclone/requests';
import { theme } from '@/components/App';
import * as yup from 'yup';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

type CreateModalProps = {
  onSubmit: (data: PostCrowdfundingPlanI) => void;
};

const schema = yup.object().shape({
  title: yup.string().required('Campo requerido'),
  amountToCollect: yup
    .number()
    .positive()
    .integer()
    .required('Campo requerido')
    .typeError('Debe ser un numero positivo')
});

export const CreateModal: FunctionComponent<CreateModalProps> = ({ onSubmit }) => {
  const [description, setDescription] = useState('');
  const [showAmountToCollect, setShowAmountToCollect] = useState<boolean>(false);

  const {
    handleSubmit: handleSubmitForm,
    formState: { errors },
    control
  } = useForm({ resolver: yupResolver(schema) });

  const handleSubmit: SubmitHandler<FieldValues> = (data) => {
    const request: PostCrowdfundingPlanI = {
      title: data.title,
      description,
      amount_to_collect: data.amountToCollect,
      show_amount_to_collect: !showAmountToCollect
    };

    onSubmit(request);
  };

  const toggleCheckbox = () => setShowAmountToCollect(!showAmountToCollect);

  return (
    <form>
      <ModalHeader>Crear un Objetivo</ModalHeader>
      <ModalContent>
        <HelpText>
          Al definir un Objetivo, tus seguidores podrán realizar aportes y ayudarte a alcanzarlo.
        </HelpText>
        <InputContainer>
          <Controller
            name="title"
            control={control}
            render={({ field: { onChange } }) => (
              <Input
                onChange={onChange}
                capitalize={false}
                inputId="title"
                placeholder="Título del Objetivo"
                error={errors?.title?.message as string}
              />
            )}
          />
        </InputContainer>
        <InputContainer>
          <InputRichText
            inputId="description"
            placeholder="Descripción"
            helpText="Describí tu objetivo y cómo te ayudarán a alcanzarlo"
            onChange={(description: string) => setDescription(description)}
            value={description}
          />
        </InputContainer>
        <InputContainer>
          <Controller
            name="amountToCollect"
            control={control}
            render={({ field: { onChange } }) => (
              <Input
                onChange={onChange}
                capitalize={false}
                inputId="amountToCollect"
                placeholder="Monto a alcanzar"
                helpText="Indicá el monto para cumplir tu Objetivo"
                pattern="\d*"
                error={errors?.amountToCollect?.message as string}
              />
            )}
          />
        </InputContainer>
        <CheckboxContainer>
          <Checkbox enable={showAmountToCollect} onClick={toggleCheckbox} />
          <DescriptionWrapper>
            <Description>Ocultar monto a alcanzar</Description>
            <TooltipWrapper>
              <Tooltip
                content="Mostraremos el total recaudado pero no tu monto a alcanzar"
                position="top"
                showPointer={false}
              >
                <Icon name="question_circle" width="15px" height="15px" color={theme.colors.grey[800]} />
              </Tooltip>
            </TooltipWrapper>
          </DescriptionWrapper>
        </CheckboxContainer>
      </ModalContent>
      <ModalFooter>
        <Button
          fullWidth
          onClick={handleSubmitForm(handleSubmit, () => {
            // Handle error
          })}
        >
          Crear Objetivo
        </Button>
      </ModalFooter>
    </form>
  );
};

const HelpText = styled.span`
  display: block;
  color: ${theme.colors.grey[700]};
  font-size: ${theme.font.size.sm};
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  padding-bottom: 15px;
`;

const CheckboxContainer = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  width: 100%;
`;

const Description = styled.span`
  flex: 1;
  color: ${theme.colors.grey[700]};
  font-size: ${theme.font.size.lg};
  max-width: fit-content;
`;

const TooltipWrapper = styled.div`
  flex: 2;
  margin-left: 0.5rem;
  max-width: 20%;
`;
