import { trackGenericEvent } from '@/analytics';
import { useMixpanelTrackPage } from '@/analytics/hooks';
import { Button, Column, ResponsiveContainer, Row } from '@/components';
import { useAuth } from '@/contexts';
import { mercado_pago } from '@/static/images';
import { SupportedCountriesE, WalletTypeE } from '@/types/cyclone/models';
import { faMoneyBill1 } from '@fortawesome/free-regular-svg-icons';
import { faBank } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

export const PaymentGateways: React.FC = () => {
  useMixpanelTrackPage('Payment Gateways');
  const { session } = useAuth();
  const navigate = useNavigate();
  const country = session?.vendor?.country || '';

  const paymentGateways = session?.vendor?.payment_gateways || [];
  const isMercadoPagoAvailable = country !== SupportedCountriesE.CHILE;
  const isWalletAssociated = paymentGateways.includes(WalletTypeE.MERCADO_PAGO);
  const isCashEnabled = session?.vendor?.is_payment_cash_enabled;
  const isBankTransferEnabled = session?.vendor?.is_payment_bank_transfer_enabled;

  return (
    <ResponsiveContainer>
      <section className="my-2">
        <Row align="center" justify="space-between" className="mb-4 pb-4">
          <Column>
            <p className="text-xl sm:text-3xl">Medios de pago</p>
            <p className="text-sm text-[#828282]">
              Gestioná los medios de pago aceptados en tu negocio.{' '}
              <Link
                to="https://ayuda.agora.red/es/articles/7920323-como-te-pagan-tus-clientes"
                target="_blank"
                rel="noreferrer"
                onClick={() => trackGenericEvent('Button Help Center Clicked')}
                className="cursor-pointer text-blue"
              >
                Más info.
              </Link>
            </p>
          </Column>
        </Row>
        <Column align="center" justify="center" gap={16} className="w-full">
          {isMercadoPagoAvailable && (
            <article className="max-w-3xl w-full flex gap-4 items-center shadow-[0px_1px_4px_0px_#00000040] p-4">
              <div className="w-16 h-16 flex items-center justify-center">
                <img src={mercado_pago} className="object-contain" />
              </div>
              <Column className="w-[calc(70%-64px)] sm:w-[calc(80%-64px)]">
                <h2 className="font-medium">Mercado Pago</h2>
                <p className="text-[#868686] text-sm">Conectá tu billetera y ofrecé pagos con tarjetas</p>
              </Column>
              <div className="w-[20%] sm:w-[30%]">
                <Button
                  onClick={() => navigate('/ajustes/medios-de-pago/mercadopago')}
                  fullWidth
                  rounded
                  variant={isWalletAssociated ? 'link' : 'filled'}
                >
                  {isWalletAssociated ? 'Administrar' : 'Conectar'}
                </Button>
              </div>
            </article>
          )}
          <article className="max-w-3xl w-full flex gap-4 items-center shadow-[0px_1px_4px_0px_#00000040] p-4">
            <div className="w-16 h-16 flex items-center justify-center">
              <FontAwesomeIcon icon={faBank} size="2x" color="#9e9a82" />
            </div>
            <Column className="w-[calc(70%-64px)] sm:w-[calc(80%-64px)]">
              <h2 className="font-medium">Pagos con transferencia</h2>
              <p className="text-[#868686] text-sm">
                Ofrecé pagos con transferencia bancaria o desde billeteras virtuales
              </p>
            </Column>
            <div className="w-[20%] sm:w-[30%]">
              <Button
                onClick={() => navigate('/ajustes/medios-de-pago/transferencia')}
                fullWidth
                rounded
                variant={isBankTransferEnabled ? 'link' : 'filled'}
              >
                {isBankTransferEnabled ? 'Administrar' : 'Habilitar'}
              </Button>
            </div>
          </article>
          <article className="max-w-3xl w-full flex gap-4 items-center shadow-[0px_1px_4px_0px_#00000040] p-4">
            <div className="w-16 h-16 flex items-center justify-center">
              <FontAwesomeIcon icon={faMoneyBill1} size="2x" color="#1A652A" />
            </div>
            <Column className="w-[calc(70%-64px)] sm:w-[calc(80%-64px)]">
              <h2 className="font-medium">Pagos en efectivo</h2>
              <p className="text-[#868686] text-sm">
                Ofrecé a tus clientes la posibilidad de pagarte en efectivo
              </p>
            </Column>
            <div className="w-[20%] sm:w-[30%]">
              <Button
                onClick={() => navigate('/ajustes/medios-de-pago/efectivo')}
                fullWidth
                rounded
                variant={isCashEnabled ? 'link' : 'filled'}
              >
                {isCashEnabled ? 'Administrar' : 'Habilitar'}
              </Button>
            </div>
          </article>
        </Column>
      </section>
    </ResponsiveContainer>
  );
};
